
import { Link } from "react-router-dom";
import { MainLayout, PageHeader } from "../../../components/layout";

export function Careers() {
  return (
    <MainLayout>
      <PageHeader header="Careers" />
      <div className="w-10/12 flex flex-col mx-auto my-6 md:my-16">
        {/* <div className="w-1/2 my-12">
          <p className="my-4 mx-10 text-green-800">
          We currently have no available positions.
          <br />
          
          </p>
        </div> */}
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
          <div>
            <div className="rounded-xl h-48 bg-green-600 text-center py-16">
              <p className="text-white py-4">Web Developer</p>
              <button className="bg-white rounded-full px-4 py-2 mx-auto">
                <Link to="/careers/web-development">Apply now</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
