import {
    GET_FAQS,
    FAQ_ERROR,
    GET_FAQ,
  } from '../actions/types';
  
  const initialState = {
    faqs: [],
    faq: null,
    loading: true,
    error: {}
  };
  
  function faqReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_FAQS:
        return {
          ...state,
          faqs: payload,
          loading: false
        };
      case GET_FAQ:
        return {
          ...state,
          faq: payload,
          loading: false
        };
      case FAQ_ERROR:
        return {
          ...state,
          error: payload,
          loading: false
        };
      default:
        return state;
    }
  }
  
  export default faqReducer;
  