import React from "react";

export const PersonItem = ({ src, title, name, size, classNames }) => {
  return (
    <>
      <div className={`text-center bg-white h-[340px] ${classNames}`}>
        <div className="">
          <img
            src={src}
            alt={name}
            style={{
                height: size,
                width: size,
            }}
            className="rounded-full mx-auto my-2 border border-4 border-green-200"
          />
        </div>
        <div className="py-2 mx-2">
          <p className="font-bold text-xl">{name}</p>
          <p>{title}</p>
        </div>
      </div>
    </>
  );
};
