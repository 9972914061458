export const WorkshopBody = () => {
  return (
    <div className="tab-pane fade show active">
      <h1 className="text-2xl text-black font-bold">Workshops & Training</h1>
      <div className="bg-green-700 w-24 h-4 mb-4"></div>
      <p className="text-gray-500 text-md">
        Join and be part of world class NCDC activities and events that range
        from Educational Training, And deep team work building activities for
        your educational institution, research center and other various training
        modules
      </p>
    </div>
  );
};
