import React from "react";
import { Link } from "react-router-dom";
import { MainLayout, PageHeader } from "../../../components/layout";

export const Departments = () => {
  return (
    <MainLayout>
      <PageHeader header="Departments" />
      <div className="w-4/6 lg:w-5/6 mx-auto my-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-4">
          <Link
            to="/departments/office-of-director"
            className="bg-gray-300 p-10 rounded hover:bg-green-500"
          >
            <span className="text-lg font-bold">Office of Director</span>
          </Link>
          <Link
            to="/departments/finance"
            className="bg-gray-300 p-10 rounded hover:bg-green-500"
          >
            <span className="text-lg font-bold">Finance</span>
          </Link>
          <Link
            to="/departments/research-consultancy"
            className="bg-gray-300 p-10 rounded hover:bg-green-500"
          >
            <span className="text-lg font-bold">Research, Consultancy</span>
          </Link>
          <Link
            to="/departments/human-resources"
            className="bg-gray-300 p-10 rounded hover:bg-green-500"
          >
            <span className="text-lg font-bold">Human Resource</span>
          </Link>
          <Link
            to="/departments/curriculum-instruction"
            className="bg-gray-300 p-10 rounded hover:bg-green-500"
          >
            <span className="text-lg font-bold">Curriculum & Instruction</span>
          </Link>
          <Link
            to="/departments/primary-department"
            className="bg-gray-300 p-10 rounded hover:bg-green-500"
          >
            <span className="text-lg font-bold">Primary Department</span>
          </Link>
          <Link
            to="/departments/literature-bureau"
            className="bg-gray-300 p-10 rounded hover:bg-green-500"
          >
            <span className="text-lg font-bold">Literature Bureau</span>
          </Link>
          <Link
            to="/departments/quality-publications"
            className="bg-gray-300 p-10 rounded hover:bg-green-500"
          >
            <span className="text-lg font-bold">
              Quality Assurance & Publishing
            </span>
          </Link>
          <Link
            to="/departments/special-needs-education"
            className="bg-gray-300 p-10 rounded hover:bg-green-500"
          >
            <span className="text-lg font-bold">Special Needs Education</span>
          </Link>
          <Link
            to="/departments/early-childhood-care"
            className="bg-gray-300 p-10 rounded hover:bg-green-500"
          >
            <span className="text-lg font-bold">
              Early Childhood Care & Education
            </span>
          </Link>
        </div>
      </div>
    </MainLayout>
  );
};
