import React from "react";
import { MainLayout, PageHeader } from "../../../components/layout";

export const SpecialNeeds = () => {
  return (
    <MainLayout>
      <PageHeader
        header="Special Needs Education"
        to="/departments"
        parent="Departments"
      />
      <div className="w-5/6 mx-auto my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="font-semibold text-gray-500 col-span-3">
            <div className="m-4">
              <p className="my-4">
                Special needs education is the practice of educating learners in
                a way that provides accommodations that address their individual
                differences, disabilities and special needs. This process
                involves the individually planned and systematically monitored
                arrangements of teaching procedures, adapted equipment and
                materials, and accessible settings. These interventions are
                designed to assist individuals with special needs achieve a
                higher level of personal self-sufficiency and success in school
                and in their community, which may not be available if the
                learners were only given access to a typical classroom
                education.
              </p>
              <p className="my-4">
                Special education aims to provide accommodated education for
                learners with disabilities such as; learning disabilities (such
                as; dyslexia), communication disorders, emotional and behavioral
                disorders (such as; ADHD), physical disabilities (such as
                cerebral palsy, muscular dystrophy, spinal bifida), and
                development disabilities (such as autistic spectrum disorder and
                intellectual disabilities) and other disabilities.
              </p>
              <div>
                <p className="my-4">Vision</p>
                <p className="my-4">
                  Learners and teachers with special needs Accessing Quality
                  educational learning/instructional materials
                </p>
              </div>
              <div>
                <p className="my-4">Mission</p>
                <p className="my-4">
                  To coordinate and support the provision of quality and
                  relevant educational materials to all Ugandans.
                </p>
              </div>
              <p className="text-xl my-4">DEPARTMENT RESPONSIBILITIES.</p>
              <ul className="list-disc">
                <li>
                  Supports education that focuses on learners with disabilities
                  and other barriers to learning and development.
                </li>
                <li>
                  Provide learning support by initiating curricular materials
                  that are relevant to various categories of personnel with
                  special educational needs.
                </li>
                <li>
                  To advocate for Inclusive Education (IE) through embracing
                  modifications/adaptations in curricular, teaching methods,
                  teaching/learning resources, medium of communication and
                  adjusting the learning environment to meet individual learning
                  needs.
                </li>
                <li>
                  Takes cognizance of and seeks to mitigate factors that form
                  barriers to learner's participation in learning and
                  development.
                </li>
                <li>
                  Advocates for change of attitudes, behaviour, teaching
                  methods, curriculum, environment and allocation of human,
                  material and financial resources to meet the educational needs
                  of all learners. Envisage the application of technology in
                  teaching and learning of learners with special educational
                  needs to address issues of quality and equity.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src="http://ncdc-site.herokuapp.com/_nuxt/img/John_Habiyaremye.726cd8d.png"
              alt="dr grace k baguma"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2">Mr. John Habiyaremye</p>
            <p className="">Manager, Human Resources and Administration</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
