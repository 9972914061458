export const History = () => {
  return (
    <>
      <div className="py-8 px-12 flex flex-col justify-center bg-slate-100">
        <p className="font-semibold text-2xl my-2">Our History</p>
        <p className="my-2 font-semibold">
          Our strategic areas of focus for the next five years shall be guided
          by the following key priority areas:
        </p>
        <p className="my-2 text-gray-600">
          The National Curriculum Development Center was established as a center
          to do detailed planning and implementation of curriculum development
          projects, training courses and other activities and ascertain the
          academic and Professional Content of courses developed at the center.
          The Centre initiates processes for curricula research, review and/ or
          reform; updates, tests and coordinates the implementation of
          educational curricula at all levels of learning (Primary, Secondary
          and Tertiary), and guides the implementation of improved educational
          curricula and pedagogy.
        </p>
      </div>
    </>
  );
}
