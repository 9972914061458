import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { MainLayout } from "../../../components/layout";
import { CirriculumBody } from "../components/body/curriculumBody";
import PublicationBody from "../components/body/publicationBody";
import { PrintingBody } from "../components/body/printingBody";
import { EducationBody } from "../components/body/educationBody";
import { SteBody } from "../components/body/steBody";
import { WorkshopBody } from "../components/body/workshopBody";

import { Curriculum } from "../components/headers/curriculum";
import { Publication } from "../components/headers/publication";
import { Printing } from "../components/headers/printing";
import { Education } from "../components/headers/education";
import { Ste } from "../components/headers/ste";
import { Workshop } from "../components/headers/workshop";

const servicesOffered = [
  "Curriculum Development",
  "Publication Evaluation",
  "Printing & Publication",
  "Education Consultancy",
  "Science, Technology Equipment Production (STEP)",
  "Workshops & Training",
];

export const Services = () => {
  const [activeBtn, setActiveBtn] = useState(0);

  return (
    <MainLayout>
      {servicesOffered[activeBtn] === "Curriculum Development" && (
        <Curriculum />
      )}
      {servicesOffered[activeBtn] === "Publication Evaluation" && (
        <Publication />
      )}
      {servicesOffered[activeBtn] === "Printing & Publication" && <Printing />}
      {servicesOffered[activeBtn] === "Education Consultancy" && <Education />}
      {servicesOffered[activeBtn] ===
        "Science, Technology Equipment Production (STEP)" && <Ste />}
      {servicesOffered[activeBtn] === "Workshops & Training" && <Workshop />}

      <div className="grid grid-cols-1 md:grid-cols-4 gap-8 w-4/6 mx-auto my-8">
        <div className="col-span-1">
          {/* Drop down */}
          <div class="flex md:hidden justify-center py-4">
            <div>
              <div class="dropdown relative">
                <a
                  class="dropdown-toggle p-6 text-black font-semibold text-lg leading-tight rounded 
                  shadow-md transition duration-150 ease-in-out flex items-center"
                  href="/"
                  type="button"
                  id="dropdownMenuButton2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {servicesOffered[activeBtn]}
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="caret-down"
                    class="w-2 ml-2"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                    ></path>
                  </svg>
                </a>
                <ul
                  class="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left
                   py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding 
                   border-none hidden"
                  aria-labelledby="dropdownMenuButton2"
                >
                  {servicesOffered.map((name, index) => (
                    <li
                      key={index}
                      className="dropdown-item p-4 font-semibold block w-5/6 bg-transparent
                    text-gray-800 hover:bg-gray-100"
                      onClick={() => {
                        setActiveBtn(index);
                      }}
                    >
                      {name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Main menu */}
          <div className="hidden md:flex text-left">
            <ul>
              {servicesOffered.map((name, index) => (
                <li
                  key={index}
                  className={
                    activeBtn === index
                      ? "font-medium border-b-2 border-green-400 py-3 hover:text-green-500"
                      : "font-medium py-3 hover:text-green-500"
                  }
                  onClick={() => setActiveBtn(index)}
                >
                  <button>{name}</button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-span-3">
          {servicesOffered[activeBtn] === "Curriculum Development" && (
            <CirriculumBody />
          )}
          {servicesOffered[activeBtn] === "Publication Evaluation" && (
            <PublicationBody />
          )}
          {servicesOffered[activeBtn] === "Printing & Publication" && (
            <PrintingBody />
          )}
          {servicesOffered[activeBtn] === "Education Consultancy" && (
            <EducationBody />
          )}
          {servicesOffered[activeBtn] ===
            "Science, Technology Equipment Production (STEP)" && <SteBody />}
          {servicesOffered[activeBtn] === "Workshops & Training" && (
            <WorkshopBody />
          )}
        </div>
      </div>
    </MainLayout>
  );
};
