import React from "react";
import { Clock, MapPin, Phone, Send } from "react-feather";
// import { Link } from "react-router-dom";
import { MainLayout, PageHeader } from "../../../components/layout";

export const Contact = () => {
  return (
    <MainLayout>
      <PageHeader header="Contact" />

      <div className="py-8 mx-20 flex justify-center">
        <div className="grid gap-4 text-xs grid-cols-1 md:grid-cols-3">
          <div className="flex items-center gap-x-2 rounded-xl border border-slate-400 p-8 shadow-md shadow-gray-300 hover:shadow-xl hover:shadow-gray-300">
            <div className="mx-4">
              <Phone className="text-green-500" />
            </div>
            <div>
              <h2 className="font-extrabold text-sm">Phone Us 24/7</h2>
              <p className="text-xs">+256 393 112088</p>
            </div>
          </div>
          <div className="flex items-center gap-x-2 rounded-xl border border-slate-400 p-8 text-center shadow-md shadow-gray-300 hover:shadow-xl hover:shadow-gray-300">
            <div className="mx-4">
              <MapPin className="text-green-500" />
            </div>
            <div className="text-left">
              <h2 className="mb-2 font-extrabold text-sm">Get Direction</h2>
              <p className="text-xs">NCDC Building Plot M838</p>
              <p className="text-xs">Kyambogo</p>
              <p className="text-xs">P.O Box 7002 Kampala, Uganda</p>
            </div>
          </div>
          <div className="flex items-center gap-x-2 rounded-xl border border-slate-400 p-8 text-center shadow-md shadow-gray-300 hover:shadow-xl hover:shadow-gray-300">
            <div className="mx-4">
              <Clock className="text-green-500" />
            </div>
            <div className="text-left">
              <h2 className="font-extrabold text-sm">Opening Hours</h2>
              <p className="text-xs font-thin">Opening Hours </p>
              <p className="text-xs">Monday to Saturay - 8 Am to 5 Pm</p>
            </div>
          </div>
        </div>
      </div>

      <div className="py-8 mx-20 flex justify-center">
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="w-full lg:h-96 lg:w-96">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7445148433744!2d32.6320821145665!3d0.34850266407159003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177db9823d00d155%3A0x599912900faaf710!2sNational%20Curriculum%20Development%20Centre!5e0!3m2!1sen!2sug!4v1652995281165!5m2!1sen!2sug"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                className="w-full h-full"
                title="map"
              ></iframe>
            </div>
            <div className="h-80 w-80 my-4">
              <p className="font-extrabold text-xl">Send us a message</p>
              <form>
                <div className="mt-4">
                  <div>
                    {/* <label for="name" className="sr-only"></label> */}
                    <input
                      id="name"
                      name="name"
                      type="name"
                      autoComplete="name"
                      required
                      className=" bg-gray-100 appearance-none rounded-full relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:text-xs:text-xs"
                      placeholder=" Your name"
                    />
                  </div>
                  <div className="mt-4">
                    {/* <label for="email" className="sr-only"></label> */}
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className=" bg-gray-100 appearance-none rounded-full relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:text-xs:text-xs"
                      placeholder="Email"
                    />
                  </div>
                  <div className="mt-4">
                    <textarea
                      className="w-full px-3 py-2 bg-gray-100 text-gray-700 border rounded focus:outline-none"
                      rows="4"
                    ></textarea>
                  </div>
                  <div className="mb-2">
                    <button
                      type="button"
                      className="mt-3 flex flex-row space-x-2 items-center px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-200 focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
                    >
                      <Send /> <span>Send Message</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
