import React, { useEffect } from "react";
import { MainLayout } from "../../../components/layout";
import faqImg from "../../../assets/images/faq.jpg";
import { getFaqs } from "../../../actions/faq";
import { FAQItem } from "../components/FAQItem";
import PropTypes from "prop-types"
import { connect } from "react-redux";

export const FAQs = () => {
  // useEffect(() => {
  //   getFaqs();
  // }, [getFaqs]);

  return (
    <MainLayout>
      <div>
        <div
          className="bg-gray-600 bg-blend-overlay text-white p-6 md:px-28 md:py-20 bg-cover bg-center"
          style={{ backgroundImage: `url(${faqImg})` }}
        >
          <p className="font-semibold text-5xl my-4">FAQs</p>
          <p className="font-semibold w-3/5">
            The Ministry of Education and Sports through the National Curriculum
            Development Centre (NCDC) revised the Lower Secondary Curriculum and
            made it competency-e based in order to produce a holistic learner
            with employable skills that are competitive in the job market. This
            curriculum was rolled out to all Secondary schools in February,
            2020.
          </p>
        </div>
      </div>

      <div className="w-5/6 mx-auto my-12">
        <div className="accordion" id="accordionExample">
          {/* {faqs.map((faq, index) => (
            <FAQItem key={index} {...faq} />
          ))} */}

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingOne">
              <button
                className="
        		accordion-button
       			 relative
        		flex
        		items-center
        		w-full
        		py-4
        		px-5
        		text-base text-gray-800 text-left
       			 bg-white
        		border-0
        		rounded-none
        			transition
        		focus:outline-none
				hover:text-green-700
      			"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                What was the main reason for reviewing the Lower Secondary
                Education Curriculum?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                <strong className="text-bold">
                  The purpose of the review was to:
                </strong>{" "}
                <br />
                &#8226; &nbsp;Produce citizens with employable skills and who
                are competitive on the job market in order to address youth
                unemployment problem.
                <br />
                &#8226; &nbsp;Reduce on the number of subject and content
                overload within each of the subjects to remove obsolete content
                and duplication.
                <br />
                &#8226; &nbsp;Promote competency-based approach to teaching
                which is learner-centred pedagogy for improved learning out
                comes to address the challenge of rote learning and theoretical
                methods of teaching and learning.
                <br />
                &#8226; &nbsp;Improve assessment by introducing classroom-based
                assessment so as to recognise the achievement of learners right
                from Ssenior One.
                <br />
                &#8226; &nbsp;Move to the criterion-based approach assessment
                which follows set standards and looks at the learner as an
                individual and not as a group.
                {/* <code>.accordion-body</code>, though the transition does limit
								overflow. */}
              </div>
            </div>
          </div>
          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingTwo">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Is it a curriculum review or reform?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                <strong className="text-bold">
                  This is a curriculum review.
                </strong>
                <br />
                It is This is because it has maintained most of the subjects but
                reviewed the content, methods of teaching and assessment. <br />
                <span className="text-bold"> A reform </span> involves a
                complete overhaul of the curriculum design and methods of
                teaching and assessing learning.
              </div>
            </div>
          </div>
          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingThree">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                What is good about this new curriculum?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                It is a curriculum that focuses on what the learners can do with
                the knowledge they have acquired (competency-based curriculum)
                other than what they know (knowledge based).
                <br /> Learners now have an opportunity to practice what they
                have learnt through research, community work, group work and
                project work, so as to create a lifelong learner.
                <br /> It gives the learner an opportunity to study a vocational
                subject for which they will be given a certificate for the world
                of work by the Directorate of Industrial Training (DIT).
                <br /> The certificate will enable the learnersr get employed
                and also allow them him or her proceed to Technical and
                vocational Vocational Education.
              </div>
            </div>
          </div>
          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingFour">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                How different is the new curriculum from the one that has been
                there?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                The new Curriculum curriculum emphasises on what the learner is
                able to do with the knowledge acquired unlike in the old
                curriculum where emphasis was on acquiring and recalling
                knowledge.
                <br /> The Learners will be engaged in activities such as
                research, fieldwork, learning projects and community work,
                unlike in the old curriculum where the teacher has been the
                centre of the learning process and as the master of knowledge.
                <br />
                The new curriculum recognises each learner’s achievement,
                however small it might be, while the old curriculum looks at a
                class as a whole and compares learners despite their different
                abilities.
                <br /> The new curriculum puts emphasis on nurturing the
                following national values:
                <br />
                &#8226; &nbsp;Respect for humanity and environment,
                <br />
                &#8226; &nbsp;Honesty,
                <br />
                &#8226; &nbsp;Justice and fairness,
                <br />
                &#8226; &nbsp;Hard work,
                <br />
                &#8226; &nbsp;Integrity,
                <br />
                &#8226; &nbsp;Creativity and innovativeness,
                <br />
                &#8226; &nbsp;Social responsibility,
                <br />
                &#8226; &nbsp;Social harmony,
                <br />
                &#8226; &nbsp;National unity,
                <br />
                &#8226; &nbsp;National consciousness and patriotism.
                <br />
                In addition, the new curriculum promotes acquisition of
                workplace skills/soft skills/generic skills/21 st century skills
                such as:
                <br />
                &#8226; &nbsp;critical thinking and problem solving,
                <br />
                &#8226; &nbsp;creativity and innovation,
                <br />
                &#8226; &nbsp;communication,
                <br />
                &#8226; &nbsp;cooperation and self-directed learning,
                <br />
                &#8226; &nbsp;mathematical computation and ICT proficiency,
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingFive">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                Which subjects are compulsory?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                For S1 and S2, the following are compulsory: English Language,
                Kiswahili, Mathematics, Chemistry, Biology, Physics,
                Entrepreneurship, History with political Political
                educationEducation, Geography, Religious Education (CRE or IRE)
                and Physical Education. * Learners with special education needs
                will offer General Science instead of Physics, Chemistry and
                Biology*. At S3 and S4, the Compulsory compulsory subjects are
                Mathematics, English, Biology, Chemistry, Physics, History with
                Political Education and Geography. * Learners with special
                education needs will offer General Science instead of Physics
                Chemistry and Biology*
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingSix">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                How many subjects is a learner expected to offer from the pool
                of 20?
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                A student will offer a maximum of 12 subjects at S1 and S2, and
                8 or 9 subjects at S3 and S4.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingSeven">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                Why were these subjects dropped or merged?
              </button>
            </h2>
            <div
              id="collapseSeven"
              className="accordion-collapse collapse"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                The subjects which were dropped were found to either contain
                obsolete content, had content that appeared in other subjects or
                attracted few students and were not cost effective to be offered
                by schools.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingEight">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                Why has it taken NCDC so long to change the Secondary School
                curriculum?
              </button>
            </h2>
            <div
              id="collapseEight"
              className="accordion-collapse collapse"
              aria-labelledby="headingEight"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                When government took a decision to reform the education system,
                it began with a review of the pre-primary education in 2005.
                This was followed with the review at the primary which was
                concluded in 2012. It was after rolling out the primary seven
                curriculum, that discussions on the need to reform the secondary
                curriculum began.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingNine">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseNine"
                aria-expanded="false"
                aria-controls="collapseNine"
              >
                Why is the curriculum review starting from Secondary and not
                Early Childhood Development (ECD) and Primary?
              </button>
            </h2>
            <div
              id="collapseNine"
              className="accordion-collapse collapse"
              aria-labelledby="headingNine"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                The curriculum review started with ECD in 2005 which put in
                place the ECD policy. The Primary curriculum review followed
                later to introduce the Thematic Curriculum (P1-P3). The rollout
                of the Primary curriculum followed a phased approach up to P7 in
                2012.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingTen">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTen"
                aria-expanded="false"
                aria-controls="collapseTen"
              >
                Which stakeholders were consulted during the development of this
                curriculum?
              </button>
            </h2>
            <div
              id="collapseTen"
              className="accordion-collapse collapse"
              aria-labelledby="headingTen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                Consultations were made with the following stakeholders:
                <br />
                &#8226; &nbsp;Small scale industries
                <br />
                &#8226; &nbsp;Faith based organisations through the Inter
                Religious Council (IRC)
                <br />
                &#8226; &nbsp;Head teachers through the Association of Secondary
                School Head Teachers of Uganda (ASSHU)
                <br />
                &#8226; &nbsp;Vice Chancellors from both private and public
                universities
                <br />
                &#8226; &nbsp;Representatives from the National Teachers’
                Colleges
                <br />
                &#8226; &nbsp;Deans of the schools for Education from both
                private and public universities
                <br />
                &#8226; &nbsp;Classroom teachers
                <br />
                &#8226; &nbsp;Renowned educationists
                <br />
                &#8226; &nbsp;Parents
                <br />
                &#8226; &nbsp;Development partners
                <br />
                &#8226; &nbsp;Parliament’s Committee on Education
                <br />
                &#8226; &nbsp;NRM Parliamentary Caucus
                <br />
                &#8226; &nbsp;Geography Association of Uganda
                <br />
                &#8226; &nbsp;Book publishers
                <br />
                &#8226; &nbsp;Traditional leaders in Uganda
                <br />
                &#8226; &nbsp;The media
                <br />
                &#8226; &nbsp;Uganda Geography Association
                <br />
                &#8226; &nbsp;Uganda National Council for Science and Technology
                The outcome from these consultations is what guided the Ministry
                of Education and Sports and NCDC on the number of subjects;
                compulsory/core and electives/optional and the content in the
                subjects.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingEleven">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEleven"
                aria-expanded="false"
                aria-controls="collapseEleven"
              >
                Did you carry out a needs assessment before designing the
                curriculum?
              </button>
            </h2>
            <div
              id="collapseEleven"
              className="accordion-collapse collapse"
              aria-labelledby="headingEleven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                Yes. A needs assessment was carried out by the Ministry of
                Education in 2007. The findings were compiled into a report
                titled “Uganda Secondary Education and Training Curriculum,
                Assessment and Examination: Roadmap for Reform”.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingTwelve">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwelve"
                aria-expanded="false"
                aria-controls="collapseTwelve"
              >
                Where else has the competency-based curriculum successfully been
                implemented?
              </button>
            </h2>
            <div
              id="collapseTwelve"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwelve"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                The competency-based curriculum has worked successfully in
                Ghana, Singapore, Botswana, Malaysia, Turkey, Kenya, Namibia,
                South Africa and Finland.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingThirteen">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThirteen"
                aria-expanded="false"
                aria-controls="collapseThirteen"
              >
                Why is Agriculture not a compulsory subject yet it is the
                backbone of the country’s economy?
              </button>
            </h2>
            <div
              id="collapseThirteen"
              className="accordion-collapse collapse"
              aria-labelledby="headingThirteen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                Agriculture is a practical subject which requires resources like
                land and equipment for it to be taught practically. Making it a
                compulsory subject will disadvantage some schools and learners
                who do not have access to the necessary facilities. It has,
                however, remained as an optional subject, for schools which have
                the required facilities. For learners interested in pursuing
                agriculture later, subjects like Geography, Chemistry and
                Biology provide the basic content related to Agriculture.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingFourteen">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFourteen"
                aria-expanded="false"
                aria-controls="collapseFourteen"
              >
                Why was Kiswahili made compulsory? How beneficial is Kiswahili?
              </button>
            </h2>
            <div
              id="collapseFourteen"
              className="accordion-collapse collapse"
              aria-labelledby="headingFourteen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                Kiswahili language is now used across the East African region
                for communication and business transactions. It has been made a
                compulsory subject to enable Ugandans become competitive in the
                region for employment, communication and further studies.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingFourteen">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFourteen"
                aria-expanded="false"
                aria-controls="collapseFourteen"
              >
                Why was Kiswahili made compulsory? How beneficial is Kiswahili?
              </button>
            </h2>
            <div
              id="collapseFourteen"
              className="accordion-collapse collapse"
              aria-labelledby="headingFourteen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                Kiswahili language is now used across the East African region
                for communication and business transactions. It has been made a
                compulsory subject to enable Ugandans become competitive in the
                region for employment, communication and further studies.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingFifteen">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFifteen"
                aria-expanded="false"
                aria-controls="collapseFifteen"
              >
                Why make Physical Education compulsory on a curriculum already
                having many compulsory subjects?
              </button>
            </h2>
            <div
              id="collapseFifteen"
              className="accordion-collapse collapse"
              aria-labelledby="headingFifteen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                Physical Education (PE) has been compulsory for S1 and S2 since
                2008. The aim of including PE among the compulsory subjects is
                to ensure that the country develops a generation that is health
                conscious. Today, there are many lifestyle related health
                challenges which can only be addressed by creating interest in
                the learners to keep healthy and fit. The revised PE syllabus is
                not only limited to physical exercises but has been designed to
                include social and health education as well.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingSisteen">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSisteen"
                aria-expanded="false"
                aria-controls="collapseSisteen"
              >
                How is physical education going to be taught in Kampala when
                fields are being sold off?
              </button>
            </h2>
            <div
              id="collapseSisteen"
              className="accordion-collapse collapse"
              aria-labelledby="headingSisteen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                Physical Education is about engaging in physical activities for
                health, wellbeing and fun. It is different from sport that
                requires standard facilities to enable competitive
                participation.
                <br /> The Physical Education syllabus has included what can be
                taught and learnt in any learning environment.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingSeventeen">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeventeen"
                aria-expanded="false"
                aria-controls="collapseSeventeen"
              >
                Do we have enough teachers of Kiswahili to cover the whole
                country?
              </button>
            </h2>
            <div
              id="collapseSeventeen"
              className="accordion-collapse collapse"
              aria-labelledby="headingSeventeen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                There are over 1,246 Kiswahili teachers across the country and
                more teachers are still being trained in National Teacher’s
                Colleges (NTCs) and Universities every year to cover the nation.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingEighteen">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEighteen"
                aria-expanded="false"
                aria-controls="collapseEighteen"
              >
                Will all teachers be trained on the new curriculum?
              </button>
            </h2>
            <div
              id="collapseEighteen"
              className="accordion-collapse collapse"
              aria-labelledby="headingEighteen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                All teachers will be trained but at different times. The
                Ministry of Education started with nationwide teacher support,
                with 20,000 teachers of Senior One in preparation for the roll
                out early in 2020. Since the roll out is to take a phased
                approach, there will be continuous teacher support at
                regional/school level and all the teachers will eventually come
                on board. Refresher support at regional levels started with
                2,800 teachers from Eastern region which took place in August
                2020 and the training have subsequently continued and have
                covered all the regions including the head teachers and the
                District Education Officers.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingNineteen">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseNineteen"
                aria-expanded="false"
                aria-controls="collapseNineteen"
              >
                What has been done to ensure that all teachers from government
                and private schools are trained?
              </button>
            </h2>
            <div
              id="collapseNineteen"
              className="accordion-collapse collapse"
              aria-labelledby="headingNineteen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                The communication to teachers about the training is channelled
                through the head teachers’ association (ASSHU), where all head
                teachers are members. In addition to ASSHU, communication is
                done through the Science and Mathematics programme coordinators
                (SESEMAT). Those who missed out on the initial training, will
                benefit from the regional/school level trainings.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingTwenty">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwenty"
                aria-expanded="false"
                aria-controls="collapseTwenty"
              >
                Why has the training started with practicing teachers and not
                with those in teacher training institutions?
              </button>
            </h2>
            <div
              id="collapseTwenty"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwenty"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                There are two types of training of teachers; pre-service and
                in-service. Pre-service is conducted by teacher training
                institutions while in-service is conducted by the Ministry of
                Education and Sports. The Ministry is mandated to train
                in-service teachers after a curriculum change. The teacher
                training institutions have already revised their training
                programmes for the pre-service teachers to meet the demands of
                the new curriculum.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingTwentyOne">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwentyOne"
                aria-expanded="false"
                aria-controls="collapseTwentyOne"
              >
                Does government have enough resources to start the
                implementation of the new curriculum?
              </button>
            </h2>
            <div
              id="collapseTwentyOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwentyOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                Government of Uganda is committed to implementing the revised
                curriculum beginning in 2020. The Ministry of Education and
                Sports has provided the following materials to support the
                implementation of the curriculum: syllabus documents, learner
                textbooks and teacher’s guides. These materials have been
                delivered to all schools. In addition, funds have been
                identified to create awareness among the teachers on the revised
                curriculum, starting with a nationwide programme and refresher
                support at the regional level.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingTwentyTwo">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwentyTwo"
                aria-expanded="false"
                aria-controls="collapseTwentyTwo"
              >
                Can schools continue to use the textbooks that they have been
                using?
              </button>
            </h2>
            <div
              id="collapseTwentyTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwentyTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                The textbooks that have been in circulation will continue to be
                used by teachers and the learners as support materials or
                reference books.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingTwentyThree">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwentyThree"
                aria-expanded="false"
                aria-controls="collapseTwentyThree"
              >
                What is the reason for ending class time at 2:55pm instead of
                5pm?
              </button>
            </h2>
            <div
              id="collapseTwentyThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwentyThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                The new curriculum emphasises application of learnt knowledge in
                real life. For this reason, the time between 2:55pm and 4:30pm
                has been designed to enable learners engage in activities such
                as clubs, projects, research and other practical learning
                activities to enable them learn on their own and among
                themselves. This is meant to promote the generic skills of
                having citizens who are lifelong learners, critical thinkers and
                creative, and can communicate effectively.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingTwentyFour">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwentyFour"
                aria-expanded="false"
                aria-controls="collapseTwentyFour"
              >
                Are teachers expected to be part of the learning activities
                after 2:55pm?
              </button>
            </h2>
            <div
              id="collapseTwentyFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwentyFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                Every teacher will be required to supervise the after class
                activities to ensure that the learners are actually learning
                through research, group work, group discussions, co-curricular
                and project work, among others. The activities after 2.55pm will
                be part and parcel of the learning process during the teacher
                contact hours.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingTwentyFive">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwentyFive"
                aria-expanded="false"
                aria-controls="collapseTwentyFive"
              >
                What plans do you have for sustaining proper implementation of
                this curriculum?
              </button>
            </h2>
            <div
              id="collapseTwentyFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwentyFive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                Government has continuously shown commitment to improve Uganda’s
                education and this is not going to change with the new
                curriculum. Sustainability requires all sectors and stakeholders
                to play their roles. There will be continuous support to the
                schools, teaching materials and equipment will be provided,
                teacher training institutions have adjusted their programs to
                cater for the change, examinations shall be conducted at the end
                of the cycle in line with the requirements of the revised
                curriculum and there will be continuous inspection of schools.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingTwentySix">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwentySix"
                aria-expanded="false"
                aria-controls="collapseTwentySix"
              >
                Why has the Ministry trained only S1 teachers yet in reality, a
                teacher teaches all classes?
              </button>
            </h2>
            <div
              id="collapseTwentySix"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwentySix"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                The curriculum implementation guidelines world over recommend
                that a curriculum change of a magnitude like ours be implemented
                using a phased approach. The emphasis is on S1 teachers for the
                year 2020, since the rest of the teachers are to continue
                teaching under the old curriculum. All teachers will eventually
                be trained through the regional and school-based training
                programme as more classes come on board.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingTwentySeven">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwentySeven"
                aria-expanded="false"
                aria-controls="collapseTwentySeven"
              >
                How will the learners be exposed to acquiring practical skills
                when school teaching time has been reduced from ending at 5pm to
                2:55pm?
              </button>
            </h2>
            <div
              id="collapseTwentySeven"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwentySeven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                Practical skills have been embedded in the teaching and learning
                process. However, time after 2:55pm is reserved for
                consolidation of what has been learned in form of projects,
                research, community outreaches, clubs and group discussions. In
                addition, schools will make arrangements with the community to
                have their learners engage in practical hands on learning. For
                example, where a member of the community is a poultry farmer and
                the school is unable to have a poultry farm, the school can
                enter into an understanding with such a farmer so that the
                learners in Agriculture are allowed to access the poultry farm
                to engage in hands on practical learning.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingTwentyEight">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwentyEight"
                aria-expanded="false"
                aria-controls="collapseTwentyEight"
              >
                How has the review of the curriculum affected the assessment?
              </button>
            </h2>
            <div
              id="collapseTwentyEight"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwentyEight"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                Assessment has changed from comparing learners among themselves
                (norm referenced assessment) to assessing them against
                pre-determined and clearly defined standards (criterion
                referenced assessment). In the revised curriculum, formative
                assessment is to contribute to the learner’s score at the end of
                Senior Four (S4) for all the subjects.
                <br /> Learners who opt to study pre-vocational subjects will
                have an option to be assessed and certified in occupational
                competencies in those subjects.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingTwentyNine">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwentyNine"
                aria-expanded="false"
                aria-controls="collapseTwentyNine"
              >
                How will the formative assessment be implemented?
              </button>
            </h2>
            <div
              id="collapseTwentyNine"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwentyNine"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                The schools will be guided on how the records from this
                assessment will be captured so that they contribute 20% to the
                end of S4 grade.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingThirty">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThirty"
                aria-expanded="false"
                aria-controls="collapseThirty"
              >
                How will the certification of pre-vocational subjects be done?
              </button>
            </h2>
            <div
              id="collapseThirty"
              className="accordion-collapse collapse"
              aria-labelledby="headingThirty"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                Assessment and certification of pre-vocational subjects will be
                conducted by the Directorate of Industrial Training (DIT). The
                learner will register with DIT in the vocation of their choice.
                They will then be assessed on specific occupational competencies
                in order to attain a certificate. This is optional for learners.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingThirtyOne">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThirtyOne"
                aria-expanded="false"
                aria-controls="collapseThirtyOne"
              >
                Why was the certification of pre-vocational subjects introduced
                in secondary education?
              </button>
            </h2>
            <div
              id="collapseThirtyOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingThirtyOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                This has been done in line with the Skilling Uganda programme,
                so as to recognise the different skills that are acquired
                through pre-vocational subjects which give learners an
                opportunity to progress in the vocational career paths. The
                certificate will also be useful to learners who may drop out of
                school as they can use it to get employment in the areas of
                occupational proficiency.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingThirtyTwo">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThirtyTwo"
                aria-expanded="false"
                aria-controls="collapseThirtyTwo"
              >
                What are the benefits of getting a DIT certificate?
              </button>
            </h2>
            <div
              id="collapseThirtyTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingThirtyTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                The learner who opts to leave after the four years walks away
                with marketable skills that are competitive and recognised in
                the world of work.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingThirtyThree">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThirtyThree"
                aria-expanded="false"
                aria-controls="collapseThirtyThree"
              >
                Why not merge the two certificates so that the learner gets one
                comprehensive certificate for the level?
              </button>
            </h2>
            <div
              id="collapseThirtyThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThirtyThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                <br />
                &#8226; &nbsp;The 2 certificates are to be attained at different
                times by different examination bodies.
                <br />
                &#8226; &nbsp;DIT will assess and certify the candidates for the
                world of work as per its mandate, while UNEB will certify for
                the purpose of academic progression.
                <br />
                &#8226; &nbsp; The DIT assessment is for a particular occupation
                which is optional to candidates while UNEB is to assess the
                whole cohort after completing the four years of study.
                <br />
                &#8226; &nbsp;The subjects which will be assessed by DIT will
                also be assessed for the theory part by UNEB.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingThirtyFour">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThirtyFour"
                aria-expanded="false"
                aria-controls="collapseThirtyFour"
              >
                How will the system of cumulative results work for learners who
                change school during the course of study?
              </button>
            </h2>
            <div
              id="collapseThirtyFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingThirtyFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                All learners will be assigned a Learner Identification Number
                (LIN) against which the results will be stored in a digital
                learning system. With this system, the learner’s results can be
                accessed from anywhere.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingThirtyFive">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThirtyFive"
                aria-expanded="false"
                aria-controls="collapseThirtyFive"
              >
                Is continuous assessment standardised for all schools?
              </button>
            </h2>
            <div
              id="collapseThirtyFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingThirtyFive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                Yes. The learning activities have the same descriptors and
                qualifiers for evaluating the learner’s performance.
              </div>
            </div>
          </div>

          <div className="accordion-item bg-white border border-gray-200">
            <h2 className="accordion-header mb-0" id="headingThirtySix">
              <button
                className="
        accordion-button
        collapsed
        relative
        flex
        items-center
        w-full
        py-4
        px-5
        text-base text-gray-800 text-left
        bg-white
        border-0
        rounded-none
        transition
        focus:outline-none
		hover:text-green-700
      "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThirtySix"
                aria-expanded="false"
                aria-controls="collapseThirtySix"
              >
                Will UNEB continue setting exams the same way?
              </button>
            </h2>
            <div
              id="collapseThirtySix"
              className="accordion-collapse collapse"
              aria-labelledby="headingThirtySix"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body py-4 px-5 text-md">
                The curriculum change to competency-based dictates that the
                nature of questions set by an examination body need to change to
                test the acquisition of competencies. NCDC, UNEB and DIT have
                been working together in this whole process and while NCDC will
                provide the necessary assessment guidelines, UNEB and DIT will
                develop assessment regulations and develop the test items and
                assessment packages for summative assessment. Emphasis will be
                given to questions that require a learner to use the acquired
                knowledge to process the answer to the given question rather
                than questions requiring recall of knowledge. In other words,
                the questions will require high order thinking skills (HOT).
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};


FAQs.propTypes = {
  getFaqs: PropTypes.func.isRequired,
  faq: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  faq: state.faq
});

connect(mapStateToProps, { getFaqs })(FAQs);
