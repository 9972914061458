import FemaleAvatar from "../../../assets/images/female-avatar.jpeg";
import MaleAvatar from "../../../assets/images/man-avatar.jpeg";

export function Government() {
  return (
    <>
      <div className="grid grid-row md:grid-cols-4 mt-6">
        <div className="flex flex-col">
          <div className="text-center bg-white">
            <div className="">
              <img
                src={MaleAvatar}
                alt="Dr. Grace K. Baguma"
                className="rounded-full mx-auto h-28 w-auto my-2 border border-2 border-green-500"
              />
            </div>
            <div className="py-2 mx-2">
              <p className="font-bold text-xl">Prof. George Ladaah Openjuru</p>
              <p>
                Chairperson NCDC
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="text-center bg-white">
            <div className="">
              <img
                src={MaleAvatar}
                alt="Dr. Grace K. Baguma"
                className="rounded-full mx-auto h-28 w-auto my-2 border border-2 border-green-500"
              />
            </div>
            <div className="py-2 mx-2">
              <p className="font-bold text-xl">Prof. George Ladaah Openjuru</p>
              <p>
                Secretary
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="text-center bg-white">
            <div className="">
              <img
                src={MaleAvatar}
                alt="Dr. Grace K. Baguma"
                className="rounded-full mx-auto h-28 w-auto my-2 border border-2 border-green-500"
              />
            </div>
            <div className="py-2 mx-2">
              <p className="font-bold text-xl">Prof. George Ladaah Openjuru</p>
              <p>
                Minister Representative
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-col">
          <div className="text-center bg-white">
            <div className="">
              <img
                src={FemaleAvatar}
                alt="Dr. Grace K. Baguma"
                className="rounded-full mx-auto h-28 w-auto my-2 border border-2 border-green-500"
              />
            </div>
            <div className="py-2 mx-2">
              <p className="font-bold text-xl">Prof. George Ladaah Openjuru</p>
              <p>Communications and Public Relations Officer</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
