export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const POST_ERROR = 'POST_ERROR';
export const ADD_COMMENT = 'ADD_COMMENT';
export const GET_FAQS = 'GET_FAQS';
export const FAQ_ERROR = 'FAQ_ERROR';
export const GET_FAQ = 'GET_FAQ';
export const GET_JOBS = 'GET_JOBS';
export const JOB_ERROR = 'JOB_ERROR';
export const GET_JOB = 'GET_JOB';