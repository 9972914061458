import React from "react";
import PresidentImage from "../../../assets/images/Yoweri.K.M.jpg";
import { CarouselItem } from "./CarouselItem";

export const NewsEvents = () => {
  return (
    <div>
      <div className="mt-10 gap-5 mb-2">
        <div className="flex flex-col w-1/2 lg:w-full">
          <h1 className="font-bold bg-gray-200 p-4 flex flex-row justify-between items-center">
            <span className="text-2xl">News & Events</span>
            {/* <Link
              to="/news"
              className="text-md text-gray-400 flex flex-row space-x-2"
            >
              <span>More News</span> <ArrowRight />{" "}
            </Link> */}
          </h1>
        </div>
        <div
          id="carouselExampleControls"
          class="carousel slide relative"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner relative w-full overflow-hidden">
            <div class="carousel-item active relative float-left w-full">
              <CarouselItem
                title="President Yoweri K. M."
                src={PresidentImage}
                desc="The Secondary Education in Uganda is a six year cycle from
                Senior one to Senior six having learners"
                to="/posts/presidents-speech"
                time="13 days ago"
              />
            </div>
            <div class="carousel-item relative float-left w-full">
              <CarouselItem
                title="President Yoweri K. M."
                src={PresidentImage}
                desc="The Secondary Education in Uganda is a six year cycle from
                Senior one to Senior six having learners"
                to="/posts/presidents-speech"
                time="13 days ago"
              />
            </div>
            <div class="carousel-item relative float-left w-full">
              <CarouselItem
                title="President Yoweri K. M."
                src={PresidentImage}
                desc="The Secondary Education in Uganda is a six year cycle from
                Senior one to Senior six having learners"
                to="/posts/presidents-speech"
                time="13 days ago"
              />
            </div>
          </div>
          <button
            class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span
              class="carousel-control-prev-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span
              class="carousel-control-next-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};
