import React from "react";
import { Link } from "react-router-dom";
import Banner1Image from "../../../assets/images/banner1.jpeg";
import Banner2Image from "../../../assets/images/banner2.jpeg";
import Banner3Image from "../../../assets/images/banner3.png";
import Banner4Image from "../../../assets/images/banner4.png";
import Banner5Image from "../../../assets/images/banner5.png";

export const Banner = () => {
  return (
    <div
      id="carouselExampleCaptions"
      className="carousel slide carousel-fade relative"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="4"
          aria-label="Slide 5"
        ></button>
      </div>
      <div className="carousel-inner relative w-full overflow-hidden">
        <div className="carousel-item active relative float-left w-full h-1/2 md:h-1/3 lg:h-96">
          <Link to="/resources">
            <img src={Banner1Image} className="object-cover" alt="..." />
          </Link>
        </div>
        <div className="carousel-item relative float-left w-full h-1/2 md:h-1/3 lg:h-96">
        <Link to="/resources">
          <img src={Banner2Image} className="block w-full" alt="..." />
        </Link>
        </div>
        <div className="carousel-item relative float-left w-full h-1/2 md:h-1/3 lg:h-96">
          <img src={Banner3Image} className="block w-full" alt="..." />
        </div>
        <div className="carousel-item relative float-left w-full h-1/2 md:h-1/3 lg:h-96">
          <img src={Banner4Image} className="block w-full" alt="..." />
        </div>
        <div className="carousel-item relative float-left w-full h-1/2 md:h-1/3 lg:h-96">
          <img src={Banner5Image} className="block w-full" alt="..." />
        </div>
      </div>
      <button
        className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span
          className="carousel-control-prev-icon inline-block bg-no-repeat"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span
          className="carousel-control-next-icon inline-block bg-no-repeat"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};
