export const SteBody = () => {
  return (
    <div className="my-4">
      <h1 className="text-2xl text-black font-bold">
        Science, Technology Equipment Production (STEP)
      </h1>
      <div className="bg-green-700 w-24 h-4 mb-4"></div>
      <p className="my-4 w-full ">
        <ul>
          <li>
            The Science and Technology Equipment Production (STEP) Department
            has since 1987 produced low cost, high quality teaching and
            non-teaching materials including glass apparatus (boiling tubes,
            test tubes, potometers, condensers), blackboards and prototypes of
            teaching aids.
          </li>
        </ul>
      </p>
      <div className="grid grid-cols-3 gap-8">
        <img
          src="http://ncdc-site.herokuapp.com/_nuxt/img/6.5259547.png"
          alt=""
          className="h-32 w-auto"
        />
        <img
          src="http://ncdc-site.herokuapp.com/_nuxt/img/8.97f07fa.png"
          alt=""
          className="h-32 w-auto"
        />
        <img
          src="http://ncdc-site.herokuapp.com/_nuxt/img/7.9ada74c.png"
          alt=""
          className="h-32 w-auto"
        />
      </div>
    </div>
  );
};
