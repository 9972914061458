import React from "react";
import backgroundImage from "../../../assets/images/building.jpg";
import { Link } from "react-router-dom";
import { Linkedin, Facebook, Twitter, Youtube } from "react-feather";
import { Newsletter } from "../../../features/newsletter/Newsletter";
import courtOfArms from "../../../assets/images/Coat_of_arms_of_Uganda.png";

export const Footer = () => {
  return (
		<div className="mt-1">
			<div className="bg-black h-2 w-100"></div>
			<div className="bg-yellow-300 h-2 w-100"></div>
			<div className="bg-red-600 h-2 w-100"></div>
			<div
				style={{
					backgroundAttachment: "fixed",
					backgroundImage: `url(${backgroundImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
				}}>
				<div
					style={{ backgroundColor: "rgba(237,251,255,.9500000000000001)" }}
					className="lg:px-20">
					<div class="grid grid-row md:grid-cols-2 lg:grid-cols-4 gap-2">
						<div className="m-8">
							<div className="text-lg font-bold mb-8">Inside NCDC</div>
							<div className="flex flex-col space-y-2 ">
								<Link to="/about" className="hover:text-green-700">
									About NCDC
								</Link>
								<Link to="/resources" className="hover:text-green-700">
									Resources
								</Link>
								<Link to="/services" className="hover:text-green-700">
									Services
								</Link>
								<Link to="/careers" className="hover:text-green-700">
									Jobs & Careers
								</Link>
							</div>
							<div className="mt-5">
								<Link to={"/"}>
									<img src={courtOfArms} alt="..." className="h-7/12 w-7/12" />
								</Link>
							</div>
						</div>
						<div className="m-8">
							<div className="text-lg font-bold mb-2">Important Links</div>
							<div className="flex flex-col space-y-4">
								<a
									className="hover:text-green-700"
									href="https://uneb.ac.ug/"
									target="_blank"
									rel="noopener noreferrer">
									Uganda National Examinations Board (UNEB)
								</a>
								<a
									className="hover:text-green-700"
									href="https://unche.or.ug/"
									target="_blank"
									rel="noopener noreferrer">
									National Council For Higher Education (UNCHE)
								</a>
								<a
									className="hover:text-green-700"
									href="https://www.education.go.ug/"
									target="_blank"
									rel="noopener noreferrer">
									Ministry of Education and Sports
								</a>
								<a
									className="hover:text-green-700"
									href="http://www.ubteb.go.ug/"
									target="_blank"
									rel="noopener noreferrer">
									Uganda Business and Technical Examination Board (UBTEB)
								</a>
							</div>
						</div>
						<div className="m-8">
							<div className="text-lg font-bold mb-2">Contact Us</div>
							<div className="flex flex-col space-y-4">
								<span>
									NCDC Building Plot M838 Kyambogo <br />
									admin@ncdc.go.ug <br />
									P.O Box 7002 Kampala, Uganda <br />
									+256 393 112088
								</span>
								<div className="flex flex-row space-x-2">
									<a
										href="https://www.linkedin.com/company/national-curriculum-development-centre/"
										target="_blank"
										rel="noopener noreferrer"
										className="hover:text-green-700">
										<Linkedin />
									</a>
									<a
										href="https://facebook.com/"
										target="_blank"
										rel="noopener noreferrer"
										className="hover:text-green-700">
										<Facebook />
									</a>
									<a
										href="https://twitter.com/NCDCUg"
										target="_blank"
										rel="noopener noreferrer"
										className="hover:text-green-700">
										<Twitter />
									</a>
									<a
										href="https://www.youtube.com/c/NCDCUgEduc"
										target="_blank"
										rel="noopener noreferrer"
										className="hover:text-green-700">
										<Youtube />
									</a>
								</div>
							</div>
						</div>
						<Newsletter />
					</div>
				</div>
				<div
					className="flex flex-col lg:flex-row lg:justify-between py-4 px-8 lg:px-20 text-white"
					style={{
						backgroundColor: "rgba(0,0,0,.7)",
					}}>
					<div>
						© Copyright 2021 - National Curriculum Development Centre (NCDC) |
						All rights reserved.
					</div>
					{/* <div>Powered by  <a href="" className="text-blue-500">GreyPoint Media</a></div> */}
				</div>
			</div>
		</div>
	);
};
