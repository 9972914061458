
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MainLayout, PageHeader } from '../../../components/layout'
import { getPosts } from '../../../actions/post';
import { PostItem } from '../components/PostItem';

export const Posts = ({ getPosts, post: { posts } }) => {
  useEffect(() => {
    getPosts();
  }, [getPosts]);

  return (
    <MainLayout>
      <PageHeader header="News" />
      <div className="posts">
        {posts.map((post) => (
          <PostItem key={post._id} post={post} />
        ))}
      </div>
    </MainLayout>
  );
};

Posts.propTypes = {
  getPosts: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  post: state.post
});

connect(mapStateToProps, { getPosts })(Posts);

