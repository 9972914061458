import React from "react";
import { MainLayout, PageHeader } from "../../../components/layout";

export const QualityPublications = () => {
  return (
    <MainLayout>
      <PageHeader
        header="Quality Assurance & Publication"
        to="/departments"
        parent="Departments"
      />
      <div className="w-5/6 mx-auto my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="font-semibold text-gray-500 col-span-3">
            <div className="m-4">
              <p className="my-4">
                The Department of Quality Assurance and Publishing at NCDC is
                aimed at implementing quality assurance protocols in compliance
                with the national and international standards with the aim of
                attaining stakeholder satisfaction.
              </p>
              <p className="my-4">
                The department performs this role by carryout quality checks on
                materials developed at the centre and by the general public. The
                department works under the supervision of the Deputy Director
                Curriculum and Instructional Materials Development.
              </p>
              <p className="my-4">
                The Department of Quality Assurance and Publishing at NCDC is
                aimed at implementing quality assurance protocols in compliance
                with the national and international standards with the aim of
                attaining stakeholder satisfaction.
              </p>
              <p className="my-4">
                The Department of Quality Assurance and Publishing at NCDC is
                aimed at implementing quality assurance protocols in compliance
                with the national and international standards with the aim of
                attaining stakeholder satisfaction.
              </p>
              <p className="text-xl">THE ROLE OF THE DEPARTMENT</p>
              <p className="my-4">
                In order to promote a culture of quality control and management
                at the Centre execute the following are the key activities of
                the department:
              </p>
              <ul className="list-disc">
                <li>
                  To build capacity to staff at the Centre in the area of
                  quality management to ensure that the mission of the Centre is
                  achieved.
                </li>
                <li>
                  To build capacity to staff at the Centre in the area of
                  quality management to ensure that the mission of the Centre is
                  achieved.
                </li>
                <li>
                  To coordinate the setting of standards during curriculum
                  development process at the Centre.
                </li>
                <li>
                  To receive work from the academic departments and evaluate
                  make recommendations on how to improve it.
                </li>
                <li>
                  To receive and evaluate materials from the public and make
                  recommendations on their suitability for use in educational
                  institutions in Uganda.
                </li>
                <li>
                  To receive and evaluate for certification other curricular for
                  use in educational institutions in Uganda.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src="http://ncdc-site.herokuapp.com/_nuxt/img/Angela%20Kyagaba,%20Manager%20Quality%20Assurance%20and%20Publishing.2824dca.png"
              alt="dr grace k baguma"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2">Mrs. Angela Kyagaba</p>
            <p className="">Manager Quality Assurance and Publishing</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
