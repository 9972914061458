import FemaleAvatar from "../../../assets/images/female-avatar.jpeg";
import MaleAvatar from "../../../assets/images/man-avatar.jpeg";
import GraceAvatar from "../../../assets/images/council/dr_grace.png";
import KaruhangaAvatar from "../../../assets/images/council/Dr_Bernadette_N._Karuhanga.png";
import OkumuAvatar from "../../../assets/images/council/John_Okumu.png";
import AngelaAvatar from "../../../assets/images/council/Angela_Kyagaba.png";
import ChristopherAvatar from "../../../assets/images/council/Christopher_Muganga.png";
import IreneAvatar from "../../../assets/images/council/Irene_Nakayenga.png";
import GilbertAvatar from "../../../assets/images/council/Gilbert_Siima.png";
import JohnAvatar from "../../../assets/images/council/John_Habiyaremye.png";
import LivingstoneAvatar from "../../../assets/images/council/Livingstone.png"
import { PersonItem } from "./PersonItem";

export function Management() {
  const management1 = [
    {
      id: 1,
      name: "Dr B N. Karuhanga",
      title:
        "Deputy Director Curriculum and Instructional Materials Development",
      src: KaruhangaAvatar,
      size: "30vh",
    },
    {
      id: 2,
      name: "John Okumu",
      title: "Manager Academic Department",
      src: OkumuAvatar,
      size: "24vh",
    },
    {
      id: 3,
      name: "Angela Kyagaba",
      title: "Manager Quality Assurance and Publishing",
      src: AngelaAvatar,
      size: "24vh",
    },
    {
      id: 4,
      name: "Taddeo Nyondo",
      title: "Marketing & Communications Officer",
      src: MaleAvatar,
      size: "24vh"
    }
  ];

  const management2 = [
    {
      id: 1,
      name: "Christopher Muganga",
      title: "Deputy Director Research, Consultancy and Library Services",
      src: ChristopherAvatar,
      size: "30vh",
    },
    {
      id: 2,
      name: "",
      title: "Manager Research and Consultancy",
      src: MaleAvatar,
      size: "24vh",
    },
    {
      id: 3,
      name: "Irene Nakayenga",
      title: "Actin Manager Documentation and Library Services",
      src: IreneAvatar,
      size: "24vh",
    },
    {
      id: 4,
      name: "Gilbert Siima",
      title: "Acting Manager ICT and Multimedia",
      src: GilbertAvatar,
      size: "24vh",
    },
    {
      id: 5,
      name: "Fred Galiwango",
      title: "Manager Science and Technology Equipment Production Unit",
      src: MaleAvatar,
      size: "24vh",
    },
    {
      id: 6,
      name: "",
      title: "Manager Print and Production",
      src: MaleAvatar,
      size: "24vh",
    },
  ];

  const management3 = [
    {
      id: 1,
      name: "",
      title: "Deputy Director Finance, HR and Administration",
      src: FemaleAvatar,
      size: "30vh",
    },
    {
      id: 2,
      name: "Mr. Kwiri Stephen (CPA)",
      title: "Manager Finance",
      src: MaleAvatar,
      size: "24vh",
    },
    {
      id: 3,
      name: "John Habiyareme",
      title: "Manager Human Resource and Administration",
      src: JohnAvatar,
      size: "24vh",
    },
    {
      id: 4,
      name: "Livingstone Rugasira",
      title: "Procurement and Disposal Unit",
      src: LivingstoneAvatar,
      size: "24vh"
    }
  ];
  return (
    <>
      <div className="mx-auto mt-10">
        <PersonItem
          name="Dr. Grace K. Baguma"
          src={GraceAvatar}
          title="Executive Director"
          size={"40vh"}
          classNames="h-[420px]"

        />
      </div>
      <div className="grid grid-row md:grid-cols-3">
        <div className="flex flex-col">
          {management1.map((person, index) => (
            <PersonItem key={index} {...person} />
          ))}
        </div>
        <div className="grid grid-col">
          {management2.map((person, index) => (
            <PersonItem key={index} {...person} />
          ))}
        </div>
        <div className="flex flex-col">
          {management3.map((person, index) => (
            <PersonItem key={index} {...person} />
          ))}
        </div>
      </div>
    </>
  );
}
