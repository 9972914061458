import React from "react";
import { Link } from "react-router-dom";

export const CarouselItem = ({ to, src, title, desc, time }) => {
  return (
    <Link to={to} className="flex justify-center mt-5">
      <div className="card bg-white flex flex-row items-center space-x-6 h-auto rounded-xl p-6 space-y-2 border hover:shadow-xl cursor-pointer">
        <img
          className="h-[8rem] rounded-md transition hover:bg-cyan-300"
          src={src}
          alt="presidents speech"
        />
        <div className="space-y-1">
          <h2 className=" font-semibold text-xl transition hover:text-green-500">
            {title}
          </h2>
          <p className=" text-sm select-none">{desc}</p>
          <div className="flex justify-end">
            <p className=" font-bold text-xs text-gray-400">{time}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};
