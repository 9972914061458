import React from "react";
import { Footer } from "./components/Footer";
import { ShopNavbar } from "./components/ShopNavbar";

export const ShopLayout = ({ children }) => {
  return (
    <div>
      <ShopNavbar />
      {children}
      <Footer />
    </div>
  );
};
