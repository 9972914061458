function PublicationBody() {
  return (
    <div className="my-4">
      <h1 className="text-2xl text-black font-bold">Publication Evaluation</h1>
      <div className="bg-green-700 w-24 h-4 mb-4"></div>
      <p className=" text-gray-700 text-md">
        Building Credible and Substantial content that can be produced for the
        Ugandan Educational market is essential for the perfect delivery of your
        Publication. NCDC helps you with the perfect content delivery framework.
        Book our services today to get you in right with the Ugandan Market
      </p>
    </div>
  );
}

export default PublicationBody;
