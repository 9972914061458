import React from "react";
import { Circle } from "react-feather";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export const QuickLinkItem = ({ to, link }) => {
  return (
    <li className="mt-3">
      <Link
        to={to}
        className="flex flex-row space-x-1 items-center hover:underline"
      >
        <Circle className="h-2" /> <span>{link}</span>
      </Link>
    </li>
  );
};

QuickLinkItem.propTypes = {
  to: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
