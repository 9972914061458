import React from "react";
import { MainLayout, PageHeader } from "../../../components/layout";

export const CurriculumInstruction = () => {
  return (
    <MainLayout>
      <PageHeader
        header="Curriculum & Instruction"
        to="/departments"
        parent="Departments"
      />
      <div className="w-5/6 mx-auto my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="font-semibold text-gray-500 col-span-3">
            <p className="my-4">
              Controls, guides and implements the Centre’s research and
              consultancy programs.
            </p>
            <p className="my-4">
              Coordinates the initiation, planning and development of a research
              and consultancy strategy for the organisation that contributes to
              the overall development of the Centre’s strategic plan.
            </p>
            <p className="my-4">
              Coordinates research and consultancy projects and monitor contract
              performance to ensure that they deliver timely and cost-effective
              results that enhance the effectiveness of the Centre’s service
              delivery.
            </p>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src="http://ncdc-site.herokuapp.com/_nuxt/img/Dr_Bernadette.8d07036.png"
              alt="Mr. Habiyaremye John"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2">Dr Bernadette N. Karuhanga</p>
            <p className="">
              Director Curriculum Review and Instructional Materials Development
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
