import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { Home, KeyMilestones, MediaPress, TendersBids } from "./features/home";
import { About, GoverningCouncil, NCDCFunctions } from "./features/about";
import { Posts } from "./features/blog";
import { Services } from "./features/services";
import { CurriculumInstruction, Departments, EarlyChildhoodCare, Finance, HumanResource, LiteratureBureau, OfficeOfDirector, PrimaryDepartment, QualityPublications, ResearchConsultancy, SpecialNeeds } from "./features/departments/routes";
import { Calendar } from "./features/calendar";
import { FAQs } from "./features/faq";
import { Career, Careers } from "./features/careers";
import { Resources } from "./features/Resources/routes";
import { Contact } from "./features/contact";
import { useEffect, useState } from "react";

import LogoImage from "./assets/images/logo512.png";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Provider store={store}>
      <Router>
        {loading ? (
          <div className="flex flex-row items-center h-[100vh]">
            <img src={LogoImage} alt="Loading" className="h-32 w-auto mx-auto animate__animated animate__flipInX animate-bounce" />
          </div>
        ) : (
          <div className="transition ease-in delay-150">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/about/governance" element={<GoverningCouncil />} />

              <Route path="/services" element={<Services />} />
              <Route
                path="/services/curriculum-development"
                element={<Services />}
              />
              <Route
                path="/services/publication-evaluation"
                element={<Services />}
              />
              <Route
                path="/services/printing-and-publication"
                element={<Services />}
              />
              <Route
                path="/services/education-consultancy"
                element={<Services />}
              />
              <Route
                path="/services/science-technology"
                element={<Services />}
              />
              <Route
                path="/services/workshops-and-training"
                element={<Services />}
              />

              <Route path="/news" element={<Posts />} />

              <Route path="/about/ncdc-functions" element={<NCDCFunctions />} />

              <Route path="/departments" element={<Departments />} />
              <Route path="/departments/office-of-director" element={<OfficeOfDirector />} />
              <Route path="/departments/finance" element={<Finance />} />
              <Route path="/departments/research-consultancy" element={<ResearchConsultancy />} />
              <Route path="/departments/human-resources" element={<HumanResource />} />
              <Route path="/departments/curriculum-instruction" element={<CurriculumInstruction />} />
              <Route path="/departments/primary-department" element={<PrimaryDepartment />} />
              <Route path="/departments/literature-bureau" element={<LiteratureBureau />} />
              <Route path="/departments/quality-publications" element={<QualityPublications />} />
              <Route path="/departments/special-needs-education" element={<SpecialNeeds />} />
              <Route path="/departments/early-childhood-care" element={<EarlyChildhoodCare />} />

              <Route path="/calendar" element={<Calendar />} />
              <Route path="/key-milestones" element={<KeyMilestones />} />
              <Route path="/faqs" element={<FAQs />} />
              <Route path="/tenders-and-bids" element={<TendersBids />} />

              <Route path="/careers" element={<Careers />} />
              <Route path="/careers/web-development" element={<Career />} />

              <Route path="/media-and-press" element={<MediaPress />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
        )}
      </Router>
    </Provider>
  );
};

export default App;
