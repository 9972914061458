export function Areas() {
    return (
			<>
				<div className="py-8 px-12 flex flex-col justify-center bg-slate-100">
					<p className="font-semibold text-2xl my-2">Our strategic areas</p>
					<p className="my-2 font-semibold">
						Our strategic areas of focus for the next five years shall be guided
						by the following key priority areas:
					</p>
					<ul className="list-disc grid grid-cols-2 gap-6 text-gray-600">
						<li>
							Aligning A’ Level Curriculum with Revised O’ Level Curriculum
						</li>
						<li>Research, Consultancy and Innovation</li>
						<li>
							Supporting the formation of Language Boards with focus on minority
							ethnic communities
						</li>
						<li>
							Integration of ICT and Remote Learning in teaching and learning
							methodologies in curriculum
						</li>
						<li>
							Regulation of Alternative Curricula and quality of instructional
							materials
						</li>
						<li>Strengthening curriculum implementation support</li>
					</ul>
				</div>
			</>
		);
  }
    