import React from "react";
import { Link } from "react-router-dom";
import { MainLayout, PageHeader } from "../../../components/layout";
import bidImage from "../../../assets/images/bulb.png";
import { UnderDevelopment } from "../../../components/UnderDevelopment";

export const TendersBids = () => {
  return (
    <MainLayout>
      <PageHeader header="Tenders & Bids" />
      <div className="lg:mx-20">
        <div className="bg-green-50 h-96">
          <div className=" mx-40 h-full grid grid-cols-2 ">
            <div className="flex flex-col justify-center items-start">
              <h1 className="text-black font-bold text-4xl">Tender & Bids</h1>
              <p className="mt-8 text-black text-sm ">
                To develop and review curricula and instructional materials
                focussing on inclusiveness, competencies and life skills with
                emphasis on science.
              </p>
              <button
                className="mt-10 rounded-2xl bg-green-500 hover:bg-green-700 text-white text-sm py-3 px-4 transition"
                data-bs-toggle="modal"
                data-bs-target="#submitCurriculum"
              >
                View Bids
              </button>
            </div>
            <div className=" h-full flex flex-col justify-center items-center">
              <img src={bidImage} alt="bid" className="h-9/12 w-9/12" />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="submitCurriculum"
        tabindex="-1"
        aria-labelledby="submitCurriculumTitle"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5
                className="text-xl font-medium leading-normal text-gray-800 flex flex-row space-x-2"
                id="exampleModalScrollableLabel"
              >
                <span>Whoo there!</span>
              </h5>
              <button
                type="button"
                className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body relative p-4">
              <UnderDevelopment />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
