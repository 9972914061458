export const CirriculumBody = () => {
  return (
    <div className="my-4">
      <h1 className="text-2xl text-black font-bold">Curriculum Development</h1>
      <div className="bg-green-700 w-24 h-4 mb-4"></div>
      <p className="text-gray-800 text-md">
        Curriculum Development is the key to the Nation development structure
        that is necessary, and NCDC is focused in building and structuring the
        necessary Curriculums that should be very impertinent to the growth of
        the Ugandan nation
      </p>
    </div>
  );
};
