import React from "react";
import { Mail, MapPin } from "react-feather";
import { Socials } from "./Socials";

export const Header = () => {
  return (
    <>
      <header>
        <nav
          className="navbar navbar-expand-lg px-4 hidden shadow-md py-2 lg:px-20
         bg-black relative lg:flex items-center w-full justify-between"
        >
          <div className="px-6 w-full flex flex-wrap items-center justify-between">
            <div className="flex items-center">
              <button
                className="navbar-toggler border-0 py-3 lg:hidden leading-none text-xl bg-transparent text-gray-600 hover:font-bold transition-shadow duration-150 ease-in-out mr-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContentY"
                aria-controls="navbarSupportedContentY"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  className="w-5"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                  ></path>
                </svg>
              </button>
            </div>
            <div
              className="navbar-collapse collapse grow items-center"
              id="navbarSupportedContentY"
            >
              <ul className="navbar-nav mr-auto lg:flex lg:flex-row">
                <li className="nav-item">
                  <span
                    className="nav-link flex flex-row items-center space-x-2 pr-2 lg:px-2 py-2
                     text-white text-sm hover:font-bold transition duration-150 ease-in-out"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                  >
                    <MapPin className="h-4 w-4" />{" "}
                    <span>NCDC Building Plot M838 Kyambogo</span>
                  </span>
                </li>
                <li className="nav-item lg:ml-10">
                  <a
                    className="nav-link flex flex-row items-center space-x-2 pr-2 lg:px-2 py-2 text-white text-sm hover:font-bold transition duration-150 ease-in-out"
                    href="#!"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                  >
                    <Mail className="h-4 w-4" /> <span>admin@ncdc.go.ug</span>
                  </a>
                </li>
                <li className="nav-item lg:ml-10">
                  <a
                    className="nav-link flex flex-row items-center space-x-2 pr-2 lg:px-2 py-2 text-white text-sm hover:font-bold transition duration-150 ease-in-out"
                    href="https://webmail.ncdc.go.ug"
                    target="_blank"
                    rel="noreferrer"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                  >
                    <Mail className="h-4 w-4" /> <span>Web Mail</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex flex-row text-white space-x-2">
              <Socials />
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
