import React from 'react'
import { MainLayout, PageHeader } from '../../../components/layout'

export const MediaPress = () => {
  return (
    <MainLayout>
       <PageHeader header="Media and Press" />
    </MainLayout>
  )
}
