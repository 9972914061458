import React from "react";
import { MainLayout, PageHeader } from "../../../components/layout";

export const EarlyChildhoodCare = () => {
  return (
    <MainLayout>
      <PageHeader
        header="Early Childhood care and Education"
        to="/departments"
        parent="Departments"
      />
      <div className="w-5/6 mx-auto my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="font-semibold text-gray-500 col-span-3">
            <div className="m-4">
              <p className="my-4">
                Early childhood care and education describes the period of
                learning that takes place from birth to 8 years old. Early
                Childhood Education is a term that refers to educational
                programs and strategies geared toward children from birth to the
                age of eight. ... Early childhood education often focuses on
                guiding children to learn through play.
              </p>
              <p className="text-xl my-4">
                The Aims and Objectives of Early Childhood Care and Education
              </p>
              <p className="my-4">
                According to the “Education for National Integration and
                Development”, (Government White Paper on The Education Policy
                Review Commission Report – 1992), the aims and Objectives of
                pre-primary education are under listed:
              </p>
              <ul className="list-disc">
                <li>
                  To develop capabilities and healthy physical growth of the
                  child through play/ activities.
                </li>
                <li>
                  To help the child develop good social habits as an individual
                  and as a member of society.
                </li>
                <li>To develop moral values in the child.</li>
                <li>
                  To receive work from the academic departments and evaluate
                  make recommendations on how to improve it.
                </li>
                <li>
                  To enrich the child’s experience by developing imagination,
                  self-reliance and thinking power.
                </li>
                <li>
                  To help the child towards appreciating his/her national
                  cultural background and customs, and developing a feeling of
                  love and care for other people and for Uganda and a sense of
                  unity leading to a national stance.
                </li>
                <li>
                  To develop language and communication skills in the mother
                  tongue.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src="http://ncdc-site.herokuapp.com/_nuxt/img/Enid%20Nyendwoha,%20Acting%20Manager%20Primary%20Education.83a862f.png"
              alt="dr grace k baguma"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2">Mrs. Enid Nyendwoha</p>
            <p className="">Acting Manager Primary Education</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
