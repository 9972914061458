/** @format */

import { useState } from "react";
// import { MainLayout } from "../../../components/layout";
import { MainLayout, PageHeader } from "../../../components/layout";
import { History } from "../components/History";
import { Areas } from "../components/Areas";

import ValuesIcon from "../../../assets/images/values.png";
import VisionaryIcon from "../../../assets/images/visionary.png";
import TargetIcon from "../../../assets/images/target.png";
import NationalKnoImage from "../../../assets/images/national-knowledge.png";

export function About() {
  const [isActive, setisActive] = useState("history");

  const changeComponent = (item) => {
    setisActive(item);
  };

  return (
    <MainLayout>
      <PageHeader header="About NCDC" />
      <div className="w-5/6 mx-auto my-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          <div>
            <p className="font-semibold text-3xl my-4">Who are we</p>
            <p className="text-justify">
              The National Curriculum Development Center was established as a
              center to do detailed planning and implementation of curriculum
              development projects, training courses and other activities and
              ascertain the academic and Professional Content of courses
              developed at the center. The Centre initiates processes for
              curricula research, review and/ or reform; updates, tests and
              coordinates the implementation of educational curricula at all
              levels of learning (Primary, Secondary and Tertiary), and guides
              the implementation of improved educational curricula and pedagogy
            </p>
          </div>
          <div className="flex items-center mx-auto">
            <iframe
              className="rounded-2xl"
              height={200}
              width={350}
              src="https://www.youtube.com/embed/LfpBLTVcbGM"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="my-12">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div
              className="border border-gray-200 rounded-xl text-center items-center 
            p-8 shadow-md shadow-gray-300 hover:shadow-xl hover:bg-green-100 hover:shadow-gray-300"
            >
              <div className="h-10 w-10 mx-auto rounded-full pb-4 border-b">
                <img src={VisionaryIcon} alt="visionary" />
              </div>
              <p className="font-bold text-2xl my-4">Our <span className="text-green-600">Vision</span></p>
                <p className="text-left text-sm text-center">
                  A holistic Curriculum for producing responsible Citizens
                  equipped with productive skills
                </p>
            </div>
            <div
              className="border border-gray-200 rounded-xl text-center items-center 
            p-8 shadow-md shadow-gray-300 hover:shadow-xl hover:bg-green-100 hover:shadow-gray-300"
            >
              <div className="h-10 w-10 mx-auto rounded-full pb-4 border-b">
                <img src={TargetIcon} alt="target" />
              </div>
              <p className="font-bold text-2xl my-4">Our <span className="text-green-600">Mission</span></p>
              <p className="text-left text-sm text-center">
                To develop quality, equitable curricula and instructional
                materials to meet societal needs through research, innovation
                and stakeholder involvement
              </p>
            </div>
            <div
              className="border border-gray-200 rounded-xl text-center items-center 
            p-8 shadow-md shadow-gray-300 hover:shadow-xl hover:bg-green-100 hover:shadow-gray-300"
            >
              <div className="h-10 w-10 mx-auto rounded-full pb-4 border-b">
                <img src={ValuesIcon} alt="values" />
              </div>
              <p className="font-bold text-2xl my-4">Core <span className="text-green-600"> Values</span></p>
              <p className="text-left text-sm mx-8">
                <div className="mb-2">T - Teamwork</div>
               
                <div className="mb-2">I - Integrity</div>
                <div className="mb-2">E - Equity</div>
                <div className="mb-2">E - Excellence</div>
              </p>
            </div>
            <div
              className="border border-gray-200 rounded-xl text-center items-center 
            p-8 shadow-md shadow-gray-300 hover:shadow-xl hover:bg-green-100 hover:shadow-gray-300"
            >
              <div className="h-10 w-10 mx-auto rounded-full pb-4 border-b">
                <img src={VisionaryIcon} alt="visionary" />
              </div>
              <p className="font-bold text-2xl my-4">Overall <span className="text-green-600">Goal</span></p>
              <div className="text-left text-sm text-center">
                To build a firm foundation for a centre of excellence in
                curriculum and instructional materials development
              </div>
            </div>
          </div>
        </div>
        <div className="my-12">
          <div className=" border-b-4 border-gray-300">
            <div className="flex justify-around w-[50vw] md:w-[30vw]">
              <button
                className={
                  isActive === "history"
                    ? "mx-2 px-2 w-40 text-gray-800 bg-gray-300 font-semibold"
                    : "mx-2"
                }
                onClick={() => changeComponent("history")}
              >
                Our History
              </button>
              <button
                className={
                  isActive === "areas"
                    ? "mx-2 px-2 text-gray-800 bg-gray-300 font-semibold"
                    : "mx-2"
                }
                onClick={() => changeComponent("areas")}
              >
                Our Strategic areas
              </button>
            </div>
            {/* <button
              className={
                isActive === "team" ? "mx-4 text-sky-400 font-semibold" : "mx-4"
              }
              onClick={() => changeComponent("team")}
            >
              NCDC Team
            </button> */}
          </div>
          <div>
            {isActive === "history" && <History />}
            {isActive === "areas" && <Areas />}
            {/* {isActive === "team" && <Team />} */}
            {/* {
              if (isActive === "history") {
                <p>History</p>;
              }
              if (isActive === "areas") {
                <p>Areas</p>;
              } else {
                <p>Teams</p>;
              
            }} */}
          </div>
        </div>
      </div>
      <div className="mx-24">
        <div className="text-green-600 text-2xl font-bold"> National Knowledge and Skills Objectives</div> 
        <img src={NationalKnoImage} alt="national kno" className="w-full border border-gray-200 my-6 h-auto" />
      </div>
    </MainLayout>
  );
}
