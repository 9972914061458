import React from "react";
import { MainLayout, PageHeader } from "../../../components/layout";
import MaleAvatar from "../../../assets/images/man-avatar.jpeg"

export const HumanResource = () => {
  return (
    <MainLayout>
      <PageHeader
        header="Human Resource"
        to="/departments"
        parent="Departments"
      />

      <div className="w-5/6 mx-auto my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="font-semibold text-gray-500 col-span-3">
            <p className="m-4">
              Monitors and provides technical expertise in the accounting,
              treasury management, and financial reporting Coordinates the
              design and development of the Finance Policy Manual, policies and
              procedures in finance Prepares annual department work plans,
              budgets, investment plans for submission to the supervisor. Guides
              the Budgetary Committee during the budgeting process Ensures
              effective management of the pay roll
            </p>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src={MaleAvatar}
              alt="Mr. Habiyaremye John"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2"><em>name</em></p>
            <p className="">Manager HR & Administration</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
