import api from "../utils/api";
import { GET_FAQS, FAQ_ERROR, GET_FAQ } from "./types";

/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/

// Get faqs
export const getFaqs = () => async (dispatch) => {
  try {
    const res = await api.get("/faqs");
    console.log(res);

    dispatch({
      type: GET_FAQS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FAQ_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get faq
export const getFaq = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/faqs/${id}`);

    dispatch({
      type: GET_FAQ,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FAQ_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
