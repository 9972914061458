/** @format */

import React from "react";

const ApplicationForm = () => {
	return (
		<div>
			<form class="space-y-6 flex justify-center" action="#">
				<div className="grid grid-cols-2 gap-2">
					<div className="flex flex-col ">
						<label htmlFor="fname" className="text-gray-600 text-sm">
							First Name
						</label>
						<input
							type="text"
							name="fname"
							placeholder="Enter first name"
							className="p-2 w-48 border rounded-md outline-none text-sm mt-1"
							required
						/>
					</div>
					<div className="flex flex-col">
						<label htmlFor="lname" className="text-gray-600 text-sm">
							Last Name
						</label>
						<input
							type="text"
							name="lname"
							placeholder="Enter last name"
							className="p-2 w-48 border rounded-md outline-none text-sm mt-1"
							required
						/>
					</div>
					<div className="flex flex-col mt-2">
						<label htmlFor="email" className="text-gray-600 text-sm">
							Email
						</label>
						<input
							type="email"
							name="email"
							placeholder="Enter your email"
							className="p-2 w-48 border rounded-md outline-none text-sm mt-1"
							required
						/>
					</div>
					<div className="flex flex-col mt-2">
						<label htmlFor="phone" className="text-gray-600 text-sm">
							Phone Number
						</label>
						<input
							type="text"
							name="phone"
							placeholder="Enter first name"
							className="p-2 w-48 border rounded-md outline-none text-sm mt-1"
							required
						/>
					</div>

					<div className="flex flex-col mt-2">
						<label htmlFor="address" className="text-gray-600 text-sm">
							Address
						</label>
						<input
							type="text"
							name="address"
							placeholder="Enter your address"
							className="p-2 w-48 border rounded-md outline-none text-sm mt-1"
							required
						/>
					</div>
					<div className="flex flex-col mt-2">
						<label htmlFor="city" className="text-gray-600 text-sm">
							City
						</label>
						<input
							type="text"
							name="city"
							placeholder="Enter your city"
							className="p-2 w-48 border rounded-md outline-none text-sm mt-1"
							required
						/>
					</div>

					<div className="flex flex-col mt-2">
						<label htmlFor="postalCode" className="text-gray-600 text-sm">
							Postal Code
						</label>
						<input
							type="text"
							name="postalCode"
							placeholder="Enter postal code"
							className="p-2 w-48 border rounded-md outline-none text-sm mt-1"
							required
						/>
					</div>
					<div className="flex flex-col mt-2">
						<label htmlFor="country" className="text-gray-600 text-sm">
							Country
						</label>
						<input
							type="text"
							name="country"
							placeholder="Enter your country"
							className="p-2 w-48 border rounded-md outline-none text-sm mt-1"
							required
						/>
					</div>

					<div className="flex flex-col mt-2">
						<label htmlFor="education" className="text-gray-600 text-sm">
							Highest Education Obtained
						</label>
						<select
							class="p-2 w-48 border rounded-md outline-none text-sm mt-1"
							id="grid-education">
							<option>PhD</option>
							<option>Masters</option>
							<option>Bachelors</option>
							<option>Certificate</option>
							<option>High School</option>
						</select>
					</div>
					<div className="flex flex-col mt-2">
						<label htmlFor="experience" className="text-gray-600 text-sm">
							Years of experience
						</label>
						<select
							class="p-2 w-48 border rounded-md outline-none text-sm mt-1"
							id="grid-experience">
							<option>1 year</option>
							<option>2-3 years</option>
							<option>3 and above years</option>
							<option>0</option>
							<option> Other</option>
						</select>
					</div>

					<div className="flex flex-col mt-2">
						<label htmlFor="formFile" className="text-gray-600 text-sm">
							Resume
						</label>
						<input
							class="form-control p-2 w-48 border rounded-md outline-none text-xs mt-1"
							type="file"
							id="formFilesm"
						/>
					</div>
					<div className="flex flex-col mt-2">
						<label htmlFor="salary" className="text-gray-600 text-sm">
							Desired Pay
						</label>
						<input
							type="text"
							name="salary"
							placeholder="Enter expected salary"
							className="p-2 w-48 border rounded-md outline-none text-sm mt-1"
							required
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default ApplicationForm;
