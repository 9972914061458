import React from "react";
import { MainLayout, PageHeader } from "../../../components/layout";
import DirectorAvatar from "../../../assets/images/council/dr_grace.png";

export const OfficeOfDirector = () => {
  return (
    <MainLayout>
      <PageHeader
        header="Office of the director"
        to="/departments"
        parent="Departments"
      />
      <div className="w-5/6 mx-auto my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="font-semibold text-gray-500 col-span-3">
            <p className="my-4">
              The Center is headed by a Governing Council, the supreme
              decision-making organ charged with approval of administration and
              management policies, as well as curricula and development
              projects. The Council consists of 16 eminent persons drawn from
              several government ministries and institutions.
            </p>
            <p className="my-4">
              The Governing Council reports to the Minister, Ministry of
              Education and Sports (MOEs).
            </p>
            <p className="my-4">
              NCDC Management is headed by the Director, who is assisted by
              Deputy Directors.
            </p>
          </div>
          <div className="mx-auto col-span-2">
            <img
              src={DirectorAvatar}
              alt="dr grace k baguma"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2">Dr. Grace K. Baguma</p>
            <p className="">Executive Director, NCDC</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
