import React, { useState } from "react";
import { Download } from "react-feather";
import { PageHeader, ShopLayout } from "../../../components/layout";

import resourcesQuery from "./resources_data.json";

const categories = [
  "All",
  "Early Childhood Care and EducatIon (ECCE)",
  "Primary Curriculum",
  "Secondary Curriculum",
  "BTVET Curriculum",
];

export function Resources() {
  const [activeBtn, setActiveBtn] = useState(0);
  const [isChecked, setIsChecked] = useState(false);

  const [downloadFile, setDownloadFile] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");

  console.log(filterValue);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <ShopLayout>
      <PageHeader
        header="Resources"
        desc="You can find our resources free to download today."
      />

      <div className="m-4 lg:mx-20 p-6">
        <div className="border-b border-gray-200 pt-4 pl-4 flex justify-between">
          {categories.map((name, index) => (
            <button
              key={index}
              className={
                activeBtn === index
                  ? "p-4 rounded-t-xl border border-gray-200 bg-green-100 font-semibold"
                  : "p-4 text-green-700"
              }
              onClick={() => setActiveBtn(index)}
            >
              {name}
            </button>
          ))}
        </div>
        <div className="flex flex-col md:flex-row md:justify-between md:space-x-4 mt-8">
          <select
            className="form-select appearance-none
                                        block
                                        w-full
                                        px-3
                                        py-1.5
                                        text-sm
                                        font-normal
                                        text-gray-700
                                        bg-white bg-clip-padding bg-no-repeat
                                        border border-solid border-gray-300
                                        rounded
                                        transition
                                        ease-in-out
                                        m-0
                                        focus:outline-none"
            ariaLabel="Default select example"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
          >
            <option defaultValue>Filter By..</option>
            <option value="abridged">Abridged</option>
            <option value="O Level">O Level</option>
            <option value="A Level">A Level</option>
            <option value="Primary 1">Primary 1</option>
            <option value="Primary 2">Primary 2</option>
            <option value="Primary 3">Primary 3</option>
            <option value="Primary 4">Primary 4</option>
            <option value="Primary 5">Primary 5</option>
            <option value="Primary 6">Primary 6</option>
            <option value="Primary 7">Primary 7</option>
          </select>
          <div
            className="rounded
                    w-full
                    text-gray-700
                    bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 "
          >
            <input
              type="text"
              name="search"
              className="px-3
                    py-1.5
                    text-base
                    font-normal
                    rounded
                    w-full
                    focus:outline-none
                    "
              value={searchValue}
              placeholder="Search book title.."
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="grid grid-flow-row-dense grid-cols-5 grid-rows-5 gap-6 py-12  bg-white">
          {resourcesQuery.map(
            (book, index) =>
              (activeBtn === 0 &&
                book
                  .filter(
                    ((searchBook) =>
                      searchBook.title.toLowerCase().includes(searchValue),
                    (filterBook) => filterBook.level.includes(filterValue))
                  )
                  .map((bk, i) => {
                    return (
                      <div key={i}>
                        <div className="border border-gray-300">
                          <img src={bk.image} alt="" className="w-full" />
                        </div>
                        <div className="flex flex-col border hover:bg-gray-100">
                          <div className="text-sm bg-gray-200 py-1 px-2 w-100">
                            {bk.title}
                          </div>
                          <div className="text-xs py-1 px-2 text-gray-500">
                            {bk.desc}
                          </div>
                          <button
                            onClick={() => {
                              setDownloadFile(bk.url);
                              setDownloadUrl(bk.url);
                              console.log(downloadFile, downloadUrl);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModalCenter"
                            className="flex flex-row mx-2 text-xs items-center space-x-2 px-4 py-2 my-2 rounded-lg text-green-500 hover:bg-green-600 hover:text-white"
                          >
                            <span>Free Download</span>{" "}
                            <Download className="mx-auto h-4" />
                          </button>
                        </div>
                      </div>
                    );
                  })) ||
              (index === activeBtn &&
                book.slice(0).forEach((bk, i) => (
                  <div>
                    <div className="border border-gray-300">
                      <img src={bk.image} alt="" className="w-full" />
                    </div>
                    <div className="flex flex-col border hover:bg-gray-100">
                      <div className="text-sm bg-gray-200 py-1 px-2 w-100">
                        {bk.title}
                      </div>
                      <div className="text-xs py-1 px-2 text-gray-500">
                        {bk.desc}
                      </div>
                      <button
                        onClick={() => {
                          setDownloadFile(bk.url);
                          setDownloadUrl(bk.url);
                          console.log(downloadFile, downloadUrl);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalCenter"
                        className="flex flex-row mx-2 text-xs items-center space-x-2 px-4 py-2 my-2 rounded-lg text-green-500 hover:bg-green-600 hover:text-white"
                      >
                        <span>Free Download</span>{" "}
                        <Download className="mx-auto h-4" />
                      </button>
                    </div>
                  </div>
                )))
          )}

          <div
            className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
            id="exampleModalCenter"
            tabIndex="-1"
            ariaLabelledby="exampleModalCenterTitle"
            ariaModal="true"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
              <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                  <h5
                    className="text-xl font-medium leading-normal text-gray-800"
                    id="exampleModalScrollableLabel"
                  >
                    Download our Free Resource
                  </h5>
                  <button
                    type="button"
                    className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                    data-bs-dismiss="modal"
                    ariaLabel="Close"
                  ></button>
                </div>
                <div className="modal-body relative py-4 px-12">
                  <input
                    type="text"
                    placeholder="Name"
                    className="border border-black p-4 my-4 rounded-lg w-full"
                  ></input>
                  <input
                    type="email"
                    placeholder="Email"
                    className="border border-black p-4 my-4 rounded-lg w-full"
                  ></input>
                  <button className="p-4 bg-green-400 rounded-lg hover:bg-green-700 text-white w-full">
                    Submit
                  </button>
                  <div className="my-3">
                    No, take me to my{" "}
                    <a
                      download={downloadFile}
                      className="text-green-500"
                      href={downloadUrl}
                    >
                      download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ShopLayout>
  );
}
