import { combineReducers } from "redux";
import alert from "./alert";
import post from "./post";
import job from "./job";
import faq from "./faq";

export default combineReducers({
  alert,
  post,
  faq,
  job,
});
