import { MainLayout, PageHeader } from "../../../components/layout";

export function NCDCFunctions() {
  return (
    <MainLayout>
      <PageHeader header="Functions" />
      <div className="w-5/6 mx-auto my-12">
        <div className="font-semibold text-3xl my-4">Functions of NCDC.</div>
        <ul className="">
          <li className="my-2 bg-gray-100 p-2 py-4 m-2 hover:bg-gray-200">
            To investigate and evaluate the need for Syllabus revision and
            curriculum reform at primary, secondary and tertiary levels of
            education, in preschool and post-school education and in teacher
            education.
          </li>
          <li className="my-2 bg-gray-100 p-2 py-4 m-2 hover:bg-gray-200">
            To initiate new syllabuses, to revise existing ones, to carry out
            curriculum reform, research, testing and evaluation, to bring
            up-to-date and improve syllabuses for school and college courses.
          </li>
          <li className="my-2 bg-gray-100 p-2 py-4 m-2 hover:bg-gray-200">
            To Draft teaching schemes, textbooks, teachers manuals and
            examination syllabuses in cooperation with teaching institutions and
            examining bodies.
          </li>
          <li className="my-2 bg-gray-100 p-2 py-4 m-2 hover:bg-gray-200">
            To design and develop teaching aids and instruction materials.
          </li>
          <li className="my-2 bg-gray-100 p-2 py-4 m-2 hover:bg-gray-200">
            To devise, test and evaluate examination questions and methods of
            examining students with other appropriate teaching and examining
            bodies.
          </li>
          <li className="my-2 bg-gray-100 p-2 py-4 m-2 hover:bg-gray-200">
            To organize and conduct in-service courses of instruction for the
            acquisition of knowledge and professional skill by persons intending
            or required to teach new courses developed at the centre.
          </li>
          <li className="my-2 bg-gray-100 p-2 py-4 m-2 hover:bg-gray-200">
            To organize and conducts courses in the objectives and methods of
            curriculum development for persons required to participate in
            curriculum development work.
          </li>
          <li className="my-2 bg-gray-100 p-2 py-4 m-2 hover:bg-gray-200">
            To hold seminars and conferences on curriculum development projects
            and problems.
          </li>
          <li className="my-2 bg-gray-100 p-2 py-4 m-2 hover:bg-gray-200">
            to collect, compile, analyse and abstract statistical information on
            curriculum and matters related to curriculum.
          </li>
          <li className="my-2 bg-gray-100 p-2 py-4 m-2 hover:bg-gray-200">
            To publish information, bulletins, digests, periodicals or other
            written material concerning curriculum and other matters related to
            curriculum.
          </li>
          <li className="my-2 bg-gray-100 p-2 py-4 m-2 hover:bg-gray-200">
            To disseminate and promote general and Better Knowledge and
            Understanding of new curriculums, teaching methods and teaching
            aids.
          </li>
        </ul>
      </div>
    </MainLayout>
  );
}
