import React from "react";
import PropTypes from "prop-types";
import formatDate from "../../../utils/formatDate";
import { connect } from "react-redux";

export const PostItem = ({
  post: { _id, title, desc, category, image, createdAt },
}) => (
  <div className="post bg-white p-1 my-1">
    <div>
      <p className="my-1">{title}</p>
      <p className="my-1">{desc}</p>
      <p className="post-date">Posted on {formatDate(createdAt)}</p>
    </div>
  </div>
);

PostItem.propTypes = {
  post: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});

connect(mapStateToProps, {})(PostItem);
