/** @format */

import React from "react";
import { ArrowLeft, ArrowRight } from "react-feather";
import { Link } from "react-router-dom";
import { MainLayout } from "../../../components/layout";
import { Banner } from "../components/Banner";
import { KeyMileStones } from "../components/KeyMileStones";
import { NewsEvents } from "../components/NewsEvents";
import { QuickLinks } from "../components/QuickLinks";
import { WhatWeDo } from "../components/WhatWeDo";

export const Home = () => {
  return (
    <MainLayout>
      <div>
        <Banner />
      </div>
      <div
        class="grid grid-row md:grid-cols-2 lg:grid-cols-2 gap-12 md:gap-8 
      lg:gap-4 mx-8 lg:mx-20"
      >
        <div>
          <WhatWeDo />
          {/* who we are section */}
          <div className="mt-10 gap-5 mb-2">
            <div className="flex flex-col w-1/2 lg:w-full">
              <h1 className="font-bold bg-gray-200 p-4 flex flex-row justify-between items-center">
                <span className="text-2xl">Who We Are</span>
                <Link
                  to="/about"
                  className="text-md text-gray-400 flex flex-row space-x-2"
                >
                  <span>Read More</span> <ArrowRight />{" "}
                </Link>
              </h1>
              <p className=" mt-10 text-md text-justify flex-wrap">
                The National Curriculum Development Center was established as a
                center to do detailed planning and implementation of curriculum
                development projects, training courses and other activities and
                ascertain the academic and Professional Content of courses
                developed at the center.
              </p>
              <a
                href="/"
                className="mt-5 w-36 h-12 py-3 px-3 rounded-3xl bg-green text-white
             text-sm items-center flex justify-center"
              >
                About Us
              </a>
            </div>
          </div>
        </div>

        <div className="grid grid-col gap-8">
          <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-4">
            <KeyMileStones />
            <QuickLinks />
          </div>
          <div>
            <NewsEvents />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

// ! Add Uganda flag
