import React from "react";
import { MainLayout, PageHeader } from "../../../components/layout";

export const LiteratureBureau = () => {
  return (
    <MainLayout>
      <PageHeader
        header="Literature Bureau"
        to="/departments"
        parent="Departments"
      />
      <div className="w-5/6 mx-auto my-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div className="font-semibold text-gray-500 col-span-3">
            <div className="m-4">
              <p className="my-4">
                The Literature Bureau began in 1948 as The East African
                Literature Bureau which later became Uganda Literature Bureau
                due to the collapse of the East African Community in 1977. The
                Bureau is a platform for nurturing local authorship by promoting
                free-writing, community based or bottom-up approach to literary
                production. Its main purpose is to meet increasing demand for
                reading and instructional materials of all kinds, and to promote
                local authorship. The Bureau provides the public with publishing
                opportunities and acts as a critical reviewer for prospective
                works in all languages. Activities of the Bureau are mainly
                social services through Public-Private Partnerships. The Bureau
                also acts as the publisher of the last resort. This means that
                it provides opportunity to NCDC and prospective writers to
                publish their works when all avenues have failed. In order to
                promote creativity and diversity in writing, the Bureau
                encourages authors to write both general and subject-specific
                literature. General literature may include those in the fields
                of language, literature, culture, agriculture, law, education,
                history and scholarly journals. Specialised literature refers to
                those written in accordance with the curriculum in areas of
                Early Childhood Care and Education, Secondary, Business,
                Technical, Vocational Training and Education (BTVET).
              </p>
              <p className="font-bold">
                The Bureau’s overall objectives are to:
              </p>
              <p className="my-4">
                In order to promote a culture of quality control and management
                at the Centre execute the following are the key activities of
                the department:
              </p>
              <ul className="list-disc">
                <li>
                  Print, publish and distribute original works of the authorship
                  and translations thereon in book form throughout all countries
                  and any country which is party to the Copyright union and or
                  the Copyright Convention.
                </li>
                <li>
                  Develop and encourage indigenous authorship and creativity in
                  a manner that will turn out the best indigenous literature and
                  reflect a standard in authorship.
                </li>
                <li>
                  Assist the Government in its efforts to spread universal
                  literacy through publication of follow-up readers and suitable
                  reading materials.
                </li>
                <li>
                  Assist educational and specialised institutions through
                  publication of books relevant to their purpose and through
                  publication of their research findings in all fields of art
                  and sciences.
                </li>
                <li>
                  Publish journals and periodicals in order to widen publication
                  outlets for research, criticism and publications.
                </li>
                <li>Reproduce out-of-print works.</li>
                <li>
                  Supporting publication in minority languages and neglected or
                  less attractive subjects through affirmative action.
                </li>
                <li>
                  Coordinate literacy activities in Uganda in partnership with
                  sister institutions and development partners. Coordinate and
                  promote activities of Language Boards in Uganda.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-2 mx-auto">
            <img
              src="http://ncdc-site.herokuapp.com/_nuxt/img/Christopher_Muganga.a2502f2.png"
              alt="dr grace k baguma"
              className="h-60 rounded-full border-4 border-green-400 mx-auto"
            />
            <p className="text-2xl mt-2">Mr. Christopher Muganga</p>
            <p className="">Director Research, Evaluation and Consultancy</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
