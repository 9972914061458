import React from "react";
import { Link } from "react-router-dom";

export const PageHeader = ({ header, desc, to, parent }) => {
  return (
    <>
      <div className="about-bg">
        <div className="text-white p-12 md:px-28 md:py-10 bg0shadow">
          <p className="font-semibold text-2xl sm:text-5xl my-2">{header}</p>
          <p className="font-semibold my-4 w-full md:w-3/5">{desc}</p>
          <p className="font-semibold">
            <Link to="/">Home</Link> {to && <Link to={to}> - {parent}</Link>}
            {to && (
              <span className="text-green-500"> - {to ? header : parent}</span>
            )}
            {!parent && <span className="text-green-500"> - {header}</span>}
          </p>
        </div>
      </div>
    </>
  );
};
