import React from "react";
import GearIcon from "../../../assets/images/gear.png";
import EarlyChildhoodIcon from "../../../assets/images/bulb.png";
import PrimaryCIcon from "../../../assets/images/childrengame.png";
import SecondaryCIcon from "../../../assets/images/bookmark.png";

export const WhatWeDo = () => {
  return (
    <div className="">
      <h1 className="text-xl font-bold">What We Do</h1>
      <div className="flex flex-row bg-orange-100 mt-2 p-2">
        <div className=" flex justify-between items-center w-6/12 h-10/12">
          <img src={EarlyChildhoodIcon} className="" alt="early childhood" />
        </div>
        <div className="mb-2">
          <h1 className="text-lg font-bold">
            Early Childhood Care & Education
          </h1>
          <p className="text-sm mt-3">
            The Secondary Education in Uganda is a six year cycle from Senior
            one to Senior six having learners with average age between 14 years
            and 19 years.
          </p>
        </div>
      </div>

      <div className="flex flex-row bg-green-100 p-2">
        <div className=" flex justify-between items-center w-6/12 h-10/12">
          <img src={PrimaryCIcon} className="" alt="early childhood" />
        </div>
        <div className="mb-2">
          <h1 className="text-lg font-bold">Primary Curriculum </h1>
          <p className="text-sm mt-3">
            Pre-Primary and Primary department is responsible for development of
            curriculum and curricular materials for Pre -Primary and Primary
            cycles.
          </p>
        </div>
      </div>

      <div className="flex flex-row bg-blue-100 p-2">
        <div className=" flex justify-between items-center w-6/12 h-10/12">
          <img src={SecondaryCIcon} className="" alt="early childhood" />
        </div>
        <div className="mb-2">
          <h1 className="text-lg font-bold">Secondary Curriculum </h1>
          <p className="text-sm mt-3">
            The Secondary Education in Uganda is a six year cycle from Senior
            one to Senior six having learners with average age between 14 years
            and 19 years.
          </p>
        </div>
      </div>

      <div className="flex flex-row p-2">
        <div className=" flex justify-between items-center w-6/12 h-10/12">
          <img src={GearIcon} className="" alt="early childhood" />
        </div>
        <div className="">
          <h1 className="text-lg font-bold">BTVET Curriculum </h1>
          <p className="text-sm mt-3 flex-wrap">
            The Business, Technical and Vocational Education and Training
            (BTVET) department of NCDC, develops competence-based curricula in
            line with the BTVET Act 2008 of Skilling Uganda strategy.
          </p>
        </div>
      </div>
    </div>
  );
};
