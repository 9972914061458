import React from "react";
import { QuickLinkItem } from "./QuickLinkItem";

export const QuickLinks = () => {
  const links = [
    {
      id: 1,
      link: "Events and Calendar",
      to: "/calendar",
    },
    {
      id: 2,
      link: "FAQ",
      to: "/faqs",
    },
	{
		id: 3,
		link: "Tenders & Bids",
		to: "/tenders-and-bids"
	},
	{
		id: 4,
		link: "Career Opportunities",
		to: "/careers"
	},
	{
		id: 5,
		link: "Media and Press Kit",
		to: "/media-and-press"
	},
	{
		id: 6,
		link: "Curriculum Consultancy Services",
		to: "/services/curriculum-development"
	},
	{
		id: 7,
		link: "Publication Evaluation",
		to: "/services/publication-evaluation"
	},
	{
		id: 8,
		link: "Workshops / Training",
		to:"/services/workshops-and-training"
	},
	{
		id: 9,
		link: "Governance",
		to:"/about/governance"
	}
  ];

  return (
    <div className="md:mx-auto lg:w-auto">
      <h1 className="text-xl font-bold">Quick Links</h1>
      <div className="w-full shadow-lg bg-green-100 mt-2 p-4 rounded-md">
        <ul className="text-gray-700">
          {links.map((link) => (
            <QuickLinkItem key={link.id} to={link.to} link={link.link} />
          ))}
        </ul>
      </div>
    </div>
  );
};
