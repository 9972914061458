import React from "react";
import { Link } from "react-router-dom";
import TargetArrow from "../../../assets/images/target.png";

export const KeyMileStones = () => {
  return (
    <Link to="/key-milestones">
      <h1 className="text-xl font-bold">Key Mile Stones</h1>
      <div className="flex flex-row bg-green-600 mt-2 p-2 rounded-lg">
        <div className=" flex justify-between items-center w-4/12 h-4/12">
          <img src={TargetArrow} className=" " alt="early childhood" />
        </div>
        <div className="">
          <p className="text-sm mt-3 text-white">
            Reviewed Lower Secondary Curriculum to make it competency-based{" "}
          </p>
        </div>
      </div>
      <div className="flex flex-row bg-green-600 mt-2 p-2 rounded-lg">
        <div className=" flex justify-between items-center w-4/12 h-4/12">
          <img src={TargetArrow} className=" " alt="early childhood" />
        </div>
        <div className="">
          <p className="text-sm mt-3 text-white">
            Developed home study learning materials for self study during
            lockdown
          </p>
        </div>
      </div>

      <div className="flex flex-row bg-green-600 mt-2 p-2 rounded-lg">
        <div className=" flex justify-between items-center w-4/12 h-4/12">
          <img src={TargetArrow} className=" " alt="early childhood" />
        </div>
        <div className="">
          <p className="text-sm mt-3 text-white">
            Developed Accelerated Curriculum for learners in refugee and host
            communities
          </p>
        </div>
      </div>

      <div className="flex flex-row bg-green-600 mt-2 p-2 rounded-lg">
        <div className=" flex justify-between items-center w-4/12 h-4/12">
          <img src={TargetArrow} className=" " alt="early childhood" />
        </div>
        <div className="">
          <p className="text-sm mt-3 text-white">
            Developed Accelerated Curriculum for learners in refugee and host
            communities
          </p>
        </div>
      </div>

      <div className="flex flex-row bg-green-600 mt-2 p-2 rounded-lg">
        <div className=" flex justify-between items-center w-4/12 h-4/12">
          <img src={TargetArrow} className=" " alt="early childhood" />
        </div>
        <div className="">
          <p className="text-sm mt-3 text-white">
            Developed Abridged Curriculum to catch up for lost learning time in
            2022
          </p>
        </div>
      </div>
    </Link>
  );
};
