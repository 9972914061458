import React from "react";

export const Newsletter = () => {
  return (
		
    <div className="m-8">
      <div className="text-lg font-bold mb-2">Newsletter</div>
      <div className="flex flex-col space-y-4">
        <span>Sign up for our newsletter to receive latest</span>
        <form>
          <div>
            <input
              type="text"
              placeholder="Email Address"
              className="p-2 rounded-full bg-white border border-gray-100"
            />
          </div>
          <div className="mt-2">
            <button type="submit" className="text-white text-sm bg-green-500 p-3 rounded-full">
              Join Newsletter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
