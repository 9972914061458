import React from "react";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { ShoppingCart } from "react-feather";
import { Header } from "./Header";

export const ShopNavbar = () => {
  return (
    <>
     <Header />
      <nav
        className="
    relative
    w-full
    flex flex-wrap
    items-center
    justify-between
    lg:px-20
    bg-green-700
    text-white
    hover:text-gray-700
    focus:text-gray-700
    shadow-lg
    navbar navbar-expand-lg navbar-light
    "
      >
        <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
          <button
            className="
        navbar-toggler
        text-white
        border-0
        hover:shadow-none hover:no-underline
        py-2
        px-2.5
        bg-transparent
        focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline
      "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="bars"
              className="w-6"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
              ></path>
            </svg>
          </button>
          <div
            className="collapse navbar-collapse flex-grow items-center"
            id="navbarSupportedContent"
          >
            <Link
              className="
                  flex
                  items-center
                  text-gray-900
                  bg-white
                  p-4
                  hover:text-gray-900
                  focus:text-gray-900
                  mt-2
                  lg:mt-0
                  mr-1
                "
              to="/"
            >
              <img src={logo} className="w-20" alt="" loading="lazy" />
            </Link>
            <ul className="navbar-nav flex flex-col pl-0 lg:pl-10 list-style-none mr-auto">
              <li className="nav-item p-2">
                <Link
                  className="nav-link text-white hover:font-bold p-0"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item p-2">
                <div class="nav-link relative group">
                  <a
                    href="#"
                    class="no-underline text-white md:text-blue-dark flex items-center group-hover:border-gray-light"
                  >
                    About
                  </a>

                  <div class="items-center absolute border border-t-0 rounded-b-lg p-1 bg-white p-2 invisible group-hover:visible md:w-[15vw] z-10">
                    <Link
                      to="/about"
                      class="px-4 py-2 block text-black hover:bg-grey-lighter"
                    >
                      About NCDC
                    </Link>
                    <Link
                      to="/about/governance"
                      class="px-4 py-2 block text-black hover:bg-grey-lighter"
                    >
                      Governance
                    </Link>
                    <Link
                      to="/about/ncdc-functions"
                      class="px-4 py-2 block text-black hover:bg-grey-lighter"
                    >
                      NCDC Functions
                    </Link>
                    <Link
                      to="/departments"
                      class="px-4 py-2 block text-black hover:bg-grey-lighter"
                    >
                      Departments
                    </Link>
                  </div>
                </div>
              </li>
              <li className="nav-item p-2">
                <div class="nav-link relative group">
                  <a
                    href="#"
                    class="no-underline text-white md:text-blue-dark flex items-center group-hover:border-gray-light"
                  >
                    Services
                  </a>

                  <div class="items-center absolute border border-t-0 rounded-b-lg p-1 bg-white p-2 invisible group-hover:visible md:w-[25vw] z-10">
                    <Link
                      to="/services/curriculum-development"
                      class="px-4 py-2 block text-black hover:bg-grey-lighter"
                    >
                      Curriculum Development
                    </Link>
                    <Link
                      to="/services/publication-evaluation"
                      class="px-4 py-2 block text-black hover:bg-grey-lighter"
                    >
                      Publication Evaluation
                    </Link>
                    <Link
                      to="/services/printing-and-publication"
                      class="px-4 py-2 block text-black hover:bg-grey-lighter"
                    >
                      Printing & Publication
                    </Link>
                    <Link
                      to="/services/education-consultancy"
                      class="px-4 py-2 block text-black hover:bg-grey-lighter"
                    >
                      Education Consultancy
                    </Link>
                    <Link
                      to="/services/science-technology"
                      class="px-4 py-2 block text-black hover:bg-grey-lighter"
                    >
                      Science, Technology Equipment Production
                    </Link>
                    <Link
                      to="/services/workshops-and-training"
                      class="px-4 py-2 block text-black hover:bg-grey-lighter"
                    >
                      Workshops & Training
                    </Link>
                  </div>
                </div>
              </li>

              <li className="nav-item p-2">
                <a
                  className="nav-link text-white hover:font-bold p-0"
                  href="https://ncdcplayground.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  E-learning
                </a>
              </li>
              <li className="nav-item p-2">
                <Link
                  className="nav-link text-white hover:font-bold p-0"
                  to="/resources"
                >
                  Resources
                </Link>
              </li>
              <li className="nav-item p-2">
                <Link
                  className="nav-link text-white hover:font-bold p-0"
                  to="/careers"
                >
                  Careers
                </Link>
              </li>
              <li className="nav-item p-2">
                <Link
                  className="nav-link text-white hover:font-bold p-0"
                  to="/faqs"
                >
                  FAQs
                </Link>
              </li>
              <li className="nav-item p-2">
                <Link
                  className="nav-link text-white hover:font-bold p-0"
                  to="/contact"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex items-center relative ">
            <Link
              className="text-gray-500 hover:text-gray-700 focus:text-gray-700 mx-3"
              to="/cart"
            >
              <ShoppingCart className="text-white w-4" />
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};
