import React from 'react'
import { MainLayout, PageHeader } from '../../../components/layout'

export const KeyMilestones = () => {
  return (
		<MainLayout>
			<PageHeader header="Key Milestones" />
			<div className="w-5/6 mx-auto my-12">
				<div className="font-semibold text-3xl my-4">
					Key Milestones of NCDC.
				</div>
				<ul className="">
					<li className="my-2 bg-gray-100 p-2 py-4 m-2 hover:bg-gray-200">
						Reviewed Lower Secondary Curriculum to make it competency-based{" "}
					</li>
					<li className="my-2 bg-gray-100 p-2 py-4 m-2 hover:bg-gray-200">
						{" "}
						Developed home study learning materials for self study during
						lockdown
					</li>
					<li className="my-2 bg-gray-100 p-2 py-4 m-2 hover:bg-gray-200">
						Developed Accelerated Curriculum for learners in refugee and host
						communities
					</li>
					<li className="my-2 bg-gray-100 p-2 py-4 m-2 hover:bg-gray-200">
						Developed Accelerated Curriculum for learners in refugee and host
						communities
					</li>
					<li className="my-2 bg-gray-100 p-2 py-4 m-2 hover:bg-gray-200">
						Developed Abridged Curriculum to catch up for lost learning time in
						2022
					</li>
					
				</ul>
			</div>
		</MainLayout>
	);
}
