/** @format */

import React from "react";
import { GoogleCalendar } from "../components/Calendar";
import { MainLayout, PageHeader } from "../../../components/layout";

export const Calendar = () => {
  return (
    <MainLayout>
      <PageHeader
        header="Events & Calendar"
        desc="To develop and review curricula and instructional materials
						focussing on inclusiveness, competencies and life skills with
						emphasis on science."
      />
      <div className="mx-auto my-8">
        <GoogleCalendar />
      </div>
    </MainLayout>
  );
};
