import React from "react";
import { Facebook, Linkedin, Twitter, Youtube } from "react-feather";

export const Socials = () => {
  return (
    <>
      <a
        href="https://www.linkedin.com/company/national-curriculum-development-centre/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Linkedin />
      </a>
      <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
        <Facebook />
      </a>
      <a
        href="https://twitter.com/NCDCUg"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Twitter />
      </a>
      <a
        href="https://www.youtube.com/c/NCDCUgEduc"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Youtube />
      </a>
    </>
  );
};
