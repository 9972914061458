export const PrintingBody = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-4">
      <div className="col-span-2 mx-auto">
        <p className="font-semibold text-3xl my-4">Printing & Publication</p>
        <div className="bg-green-700 w-24 h-4 mb-4"></div>
        <p className="my-4 w-full ">
          <ul>
            <li>
              Ensures efficient and effective administration and management of
              resources in printing and production processes
            </li>
            <li>
              Ensures proper maintenance of facilities to sustain steady
              production
            </li>
            <li>Mobilizes resources and generates income for the Centre</li>
          </ul>
        </p>
        <div className="grid grid-cols-3 gap-8">
          <img
            src="http://ncdc-site.herokuapp.com/_nuxt/img/3.a717905.png"
            alt=""
            className="h-32"
          />
          <img
            src="http://ncdc-site.herokuapp.com/_nuxt/img/4.692b38b.png"
            alt=""
            className="h-32"
          />
          <img
            src="http://ncdc-site.herokuapp.com/_nuxt/img/5.5267578.png"
            alt=""
            className="h-32"
          />
        </div>
      </div>
    </div>
  );
};
