import React from 'react'
import { MainLayout } from '../../../components/layout'

export const Service = () => {
  return (
    <MainLayout>
        
    </MainLayout>
  )
}
