import React from "react";

export const GoogleCalendar = () => {
  return (
    <div className="w-100 px-20">
      <iframe
        src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Africa%2FNairobi&showNav=1&showTitle=0&src=am9uYWhnZWVrQGdtYWlsLmNvbQ&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=ZDM3cm1ua3VuNXRsaWhuZG9rNW5nZHVmMTBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=czV0dnFnNW1vZzZqbGI4NzBtM2V2ZjdpaGZjbDQ1bGRAaW1wb3J0LmNhbGVuZGFyLmdvb2dsZS5jb20&src=ZW4udWcjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23039BE5&color=%2333B679&color=%238E24AA&color=%23616161&color=%230B8043"
        className="border border-gray-100 mx-auto w-full"
        height="600"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </div>
  );
};
