export const EducationBody = () => {
  return (
    <div className="my-4">
      <h1 className="text-2xl text-black font-bold">Education Consultancy</h1>
      <div className="bg-green-700 w-24 h-4 mb-4"></div>
      <ul>
        <li>
          Controls, guides and implements the Centre’s research and consultancy
          programs
        </li>
        <li>
          Coordinates the initiation, planning and development of a research and
          consultancy strategy for the organisation that contributes to the
          overall development of the Centre’s strategic plan
        </li>
        <li>
          Coordinates research and consultancy projects and monitor contract
          performance to ensure that they deliver timely and cost-effective
          results that enhance the effectiveness of the Centre’s service
          delivery
        </li>
      </ul>
    </div>
  );
};
