import { useState } from "react";
import { MainLayout, PageHeader } from "../../../components/layout";
import { Government } from "../components/Government";
import { Management } from "../components/Management";

export function GoverningCouncil() {
  const [isActive, setisActive] = useState("govt");

  return (
    <MainLayout>
      <PageHeader
        header="Governance of NCDC"
        desc="The Centre is headed by a Governing Council, the supreme
            decision-making organ charged with approval of administration and
            management policies, as well as curricula and development projects.
            The Council consists of 16 eminent persons drawn from several
            government ministries and institutions. The Governing Council
            reports to the Minister, Ministry of Education and Sports (MOEs).
            NCDC Management is headed by the Director, who is assisted by Deputy
            Directors."
      />
      <div className="w-5/6 mx-auto my-12">
        <div className="flex border-b-4 border-gray-300 text-lg">
          <button
            className={
              isActive === "govt"
                ? "mx-4 text-green-500"
                : "mx-4 hover:text-green-500"
            }
            onClick={() => setisActive("govt")}
          >
            Governance of NCDC
          </button>
          <button
            className={
              isActive === "mgt"
                ? "mx-4 text-green-500"
                : "mx-4 hover:text-green-500"
            }
            onClick={() => setisActive("mgt")}
          >
            Management
          </button>
        </div>
        <div>
          {isActive === "govt" && <Government />}
          {isActive === "mgt" && <Management />}
        </div>
      </div>
    </MainLayout>
  );
}
