import { MainLayout, PageHeader } from "../../../components/layout";
import ApplicationForm from "./ApplicationForm";

export function Career() {
  return (
    <MainLayout>
      <PageHeader header="Web Development Job" to="/careers" parent="Careers" />
      <div className="my-12 mx-48">
        <p className="py-4 text-2xl font-semibold">Web Development Job</p>
        <strong> JOB SUMMARY</strong>
        <p className="py-4">
          We are looking for a web designer who will be responsible for creating
          great websites for our clients. Primary duties include conceptualizing
          and implementing creative ideas for client websites, as well as
          creating visual elements that are in line with our clients' branding.
          You will be working closely with our web development team to ensure
          proper and hassle-free implementation.
        </p>
        <p className="py-4">
          To be successful in this role, you will need to have excellent visual
          design skills and be proficient in graphic design software such as
          Adobe Photoshop and Adobe Illustrator.
        </p>
        <p className="py-4">
          <strong className="font-bold">Web Designer Requirements:</strong>
          <ul>
            <li>• A relevant diploma in a related field.</li>
            <li>
              • Proficiency in graphic design software including Adobe
              Photoshop, Adobe Illustrator, and other visual design tools.
            </li>
            <li>
              • Proficiency in front-end development web programming languages
              such as HTML and CSS, JQuery, and JavaScript.
            </li>
            <li>• Good understanding of content management systems.</li>
            <li>
              • Good understanding of search engine optimization principles.
            </li>
            <li>
              • Proficient understanding of cross-browser compatibility issues.
            </li>
            <li>• Excellent visual design skills.</li>
            <li>
              • Up-to-date experience with international web protocols,
              standards, and technologies.
            </li>
            <li>• Creative and open to new ideas.</li>
            <li>• Adaptable and willing to learn new techniques.</li>
            <li>• Excellent communication skills.</li>
          </ul>
        </p>
        <p className="my-4">
          <strong>RESPONSIBILITIES</strong>
		  <br />
		  • Conceptualizing creative ideas with
          clients. • Testing and improving the design of the website. •
          Establishing design guidelines, standards, and best practices. •
          Maintaining the appearance of websites by enforcing content standards.
          • Designing visual imagery for websites and ensuring that they are in
          line with branding for clients. • Working with different content
          management systems. • Communicating design ideas using user flows,
          process flows, site maps, and wireframes. • Incorporating
          functionalities and features into websites. • Designing sample pages
          including colors and fonts. • Preparing design plans and presenting
          the website structure. • REQUIRED SKILLS Computer aided design (CAD),
          Graphic Design, Drawing, painting and illustration, Creative design
          REQUIRED EDUCATION Bachelor's degree
        </p>
        <button
          className="text-white p-3 rounded-full bg-green-500 hover:bg-green-700 transition  "
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#exampleModalCenter"
        >
          Apply Now
        </button>
        <div
          class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id="exampleModalCenter"
          tabindex="-1"
          aria-labelledby="exampleModalCenterTitle"
          aria-modal="true"
          role="dialog"
        >
          <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalScrollableLabel"
                >
                  Apply for job.
                </h5>
                <button
                  type="button"
                  class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body relative p-4">
                <ApplicationForm />
              </div>
              <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                <button
                  type="button"
                  class="text-red-400 bg-gray-200 px-5 py-1.5 rounded-md inline-block"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="ml-5 text-white bg-green-500 hover:bg-green-700 transition px-5 py-1.5 rounded-md inline-block"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
