import { UnderDevelopment } from "../../../../components/UnderDevelopment";

export const Education = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-5 bg-green-100 pt-8 px-4 md:px-10 lg:px-48">
      <div className="col-span-2">
        <p className="font-semibold text-3xl my-4">Education Consultancy</p>
        <p className="my-2 w-full ">
          To develop and maintain Educational Consultancy services focusing on
          inclusiveness, competencies and life skills with emphasis on science.
        </p>
        <button className="text-white py-2 px-6 bg-green-700 hover:bg-green-900 rounded-full" data-bs-toggle="modal"
          data-bs-target="#submitEducation">
          Book a Session
        </button>
      </div>
      <div className="col-span-1 flex items-center">
        <img
          src="http://ncdc-site.herokuapp.com/_nuxt/img/All%20icons-29.35a4f0a.png "
          alt="kid with a book"
          className="h-72 md:h-64 lg:h-72 mx-auto"
        />
      </div>
      <div
        className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="submitEducation"
        tabindex="-1"
        aria-labelledby="submitCurriculumTitle"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5
                className="text-xl font-medium leading-normal text-gray-800 flex flex-row space-x-2"
                id="exampleModalScrollableLabel"
              >
                <span>Whoo there!</span>
              </h5>
              <button
                type="button"
                className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body relative p-4">
              <UnderDevelopment />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
