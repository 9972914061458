import React from "react";

export const UnderDevelopment = () => {
  return (
    <div>
      <p>Oops, this feature is still under development.</p>
      <form>
        <label className="text-sm">
          We'll let you know when it's available
        </label>
        <div className="flex flex-row mt-6">
          <input
            type="email"
            placeholder="Email Address"
            className="w-full p-2 border border-gray-200"
          />
          <button className="bg-green-700 text-white px-4">Submit</button>
        </div>
      </form>
    </div>
  );
};
